import TemplateData from './getTemplateData';
import { lazy as reactLazy } from 'react';
import { trackErrorIM } from '../fpti/fpti';

/**
 * Loads bodymovin and hearts animation json
 */
export function loadBodyMovin() {
  if (
    typeof window.bodymovin !== 'undefined' &&
    typeof window.heartsAnimationData !== 'undefined'
  ) {
    return Promise.resolve([window.bodymovin, window.heartsAnimationData]);
  }
  return Promise.all([
    loadScript(
      'https://www.paypalobjects.com/donate/bodymovin/bodymovin.min.js'
    ),
    loadScript('https://www.paypalobjects.com/donate/bodymovin/hearts.js'),
  ]).catch((error) => {
    trackErrorIM({
      errorCode: 'LAZY_LOAD_FAILED',
      errorMessage: error,
      fieldName: 'bodymovin',
    });
  });
}

/**
 * Load Progressive Onboarding component (Slick Version)
 */
export function loadSlickProgressiveOnboarding() {
  return Promise.all([
    import(
      /* webpackChunkName: 'ProgressiveonboardingSlick' */ 'portableonboarding'
    ),
  ]).catch((error) => {
    trackErrorIM({
      errorCode: 'LAZY_LOAD_FAILED',
      errorMessage: error,
      fieldName: 'progressive_slick',
    });
  });
}

// eslint-disable-next-line
function loadScript(scriptPath) {
  return new Promise((resolve, reject) => {
    const nonce = TemplateData.nonce;
    var script = document.createElement('script');
    document.getElementsByTagName('head')[0].appendChild(script);
    script.onload = function (params) {
      resolve(params);
    };
    script.onerror = function (params) {
      reject(params);
    };
    script.nonce = nonce;
    script.src = scriptPath;
  });
}

export function waitForOnload() {
  return new Promise((resolve) => {
    if (document.readyState === 'complete') {
      resolve();
    } else {
      window.addEventListener('load', () => {
        resolve();
      });
    }
  });
}

/**
 * Lazy load a React component and add preload option
 */
export function reactLazyPreload(importStatement) {
  const lazyComponent = reactLazy(importStatement);
  lazyComponent.preload = importStatement;
  return lazyComponent;
}
