import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@paypalcorp/pp-react';
import { addFeature } from '../fpti/helper';
import { FEATURES } from '../fpti/fptiConstants';
import { Message } from '@paypalcorp/worldready-react';

class GuestButton extends React.Component {
  componentDidMount() {
    addFeature(FEATURES.GUEST);
  }

  render() {
    return (
      <Button
        secondary
        className="btn-wide"
        id="payWithGuest"
        disabled={this.props.disabled}
        onClick={this.props.handleGuest}
      >
        <Message id="guest.guestButton.label.debitorcredit" />
      </Button>
    );
  }
}

GuestButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleGuest: PropTypes.func,
};

export default GuestButton;
