export const BTN_FACTORY_FORM = 'buttonFactory';
export const BTN_FACTORY_V3_FORM = 'buttonFactoryV3';
export const CAMPAIGNS_FORM = 'campaigns';
export const AUTH_STATE_ANONYMOUS = 'ANONYMOUS';
export const AUTH_STATE_LOGGEDIN = 'LOGGEDIN';
export const BTN_FACTORY_MAX_PROGRAMS = 25;
export const BTN_FACTORY_MAX_LENGTH_PROGRAMS = 127;
export const MAX_MOBILE_WIDTH = 1024;
export const MAX_CHARACTERS_RTE = 2500;
export const MAX_CHARACTERS_RTE_UPDATE_FORM = 1000;

export const CAMPAIGN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const COUNTRIES_AND_PERMIT_CURRENCY = {
  BR: 'BRL',
};

export const ERRORS = {
  COUNTRY_NOT_SUPPORTED: 'COUNTRY_NOT_SUPPORTED',
  COMPLIANCE: 'COMPLIANCE',
  GENERIC: 'GENERIC',
  OFAC_HIT: 'OFAC_CAMPAIGN_SCAN_HIT',
  RESTRICTED_ACCOUNT: 'RESTRICTED_ACCOUNT',
  RESTRICTED_IMAGE: 'RESTRICTED_IMAGE',
};

export const PAYMENT_PRODUCT = {
  PUBLIC_MONEY_POOL: 'PUBLIC_MONEY_POOL',
  DONATE_BUTTON: 'DONATE_BUTTON',
  DONATE_BUTTON_CAMPAIGN: 'DONATE_BUTTON_CAMPAIGN',
  CROWDFUNDING_CAMPAIGNS: 'CROWDFUNDING_CAMPAIGNS',
};
