import React from 'react';
import { withRouter } from 'react-router-dom';
import withTheme from '../../components/withTheme';
import { hideSpinnerOverlay } from '../../utility/helper';
import Illustration from '../../components/illustrations/Illustration';
import ConfirmationBody from '../../confirmation/ConfirmationBody';
import { isMobileOrInContext } from '../../utility/inContextDonation';
import { trackErrorPageIM } from '../../fpti/fpti';
import { PAGE_SPEC } from '../../fpti/fptiConstants';
import { Row, Col } from '@paypalcorp/pp-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
class UnilateralError extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount = () => {
    hideSpinnerOverlay();

    trackErrorPageIM({
      pageSpec: PAGE_SPEC.UNILATERAL_ERROR_PAGE,
      errorCode: 'UNILATERAL_ERROR',
    });
  };

  render() {
    const noAccountErrorMessage = new MessageFormat(this.context, {
      id: 'errors.paymentError.noAccountError',
    }).format();
    return (
      <div
        className={`donate_wrapper confirmation ${
          isMobileOrInContext() ? 'mobile_view' : ''
        }`}
      >
        <Row align="center">
          <Illustration type="warning" />
        </Row>
        <Row>
          <Col className="text-center">
            <ConfirmationBody
              message={
                noAccountErrorMessage === 'errors.paymentError.noAccountError'
                  ? "We can't complete this donation. The receiver doesn't have a valid PayPal account."
                  : noAccountErrorMessage
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}
UnilateralError.contextType = WorldReadyContext;
export default withRouter(withTheme(UnilateralError));
