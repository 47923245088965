/**
 * Card security code input used for adding a card
 */
import React from 'react';
import { TextInput } from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';

import { cardSpecPropType } from 'prop-validators';

class CardSecurityCode extends React.Component {
  static propTypes = {
    cardSpec: cardSpecPropType,
    label: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    handleChangeSecurityCode: PropTypes.func.isRequired,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,
    dir: PropTypes.string,
    helperText: PropTypes.string,
  };

  static defaultProps = {
    handleFocus: () => {},
    handleBlur: () => {},
  };

  state = { value: '', showIsRequiredError: false };

  /**
   * Handle changing the csc
   * @param {String} securityCode - the csc
   * @return {String} securityCode - numbers only
   */
  handleChangeSecurityCode = ({ target: { value: securityCode } }) => {
    securityCode = securityCode.replace(/\D+/g, '');
    this.setState({ value: securityCode });
    this.handleCheckForValue(securityCode);
    this.props.handleChangeSecurityCode(securityCode);
    return securityCode;
  };

  /**
   * Check for a security code value
   * If the field is required, we should show that this field needs a value
   * @param {String} securityCode - the csc
   */
  handleCheckForValue = (securityCode) => {
    const cscSpecs = this.props.cardSpec.fields.csc;
    const isRequired = cscSpecs.required;
    this.setState({ showIsRequiredError: isRequired && !securityCode });
  };

  /**
   * Call parent's handleFocus
   * @param {String} securityCode - the csc
   */
  handleFocus = (securityCode) => {
    this.props.handleFocus(securityCode);
  };

  /**
   * Check for a security code value and call parent's handleBlur
   * @param {String} securityCode - the csc
   */
  handleBlur = ({ target: { value: securityCode } }) => {
    this.handleCheckForValue(securityCode);
    this.props.handleBlur(securityCode);
  };

  render() {
    const cscSpecs = this.props.cardSpec.fields.csc;
    // let helpText = '●'.repeat(cscSpecs.maxlength)
    const { errorMessage } = this.props;

    return (
      <div style={{ margin: '16px 0 0', position: 'relative' }}>
        <TextInput
          type="tel"
          id="securityCode"
          name="securityCode"
          value={this.state.value}
          label={this.props.label}
          maxLength={cscSpecs.maxlength}
          pattern={cscSpecs.pattern}
          required={cscSpecs.required}
          onChange={this.handleChangeSecurityCode}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          errorText={errorMessage}
          dir={this.props.dir}
          helperText={this.props.helperText}
        />
      </div>
    );
  }
}

export default CardSecurityCode;
