import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@paypalcorp/pp-react';
import { appendCdnHostName } from '../../../lib/geoUtils';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';

class NextButtonComponent extends React.Component {
  static contextType = WorldReadyContext;
  render() {
    const buttonText = new MessageFormat(this.context, {
      id: 'common.nextButton.button.next',
    }).format();

    return (
      <Button
        branded
        className="btn-wide next-button"
        id="payWithPP"
        disabled={this.props.disabled}
        onClick={this.props.handleNext}
      >
        <img
          src={appendCdnHostName('/paypal-ui/logos/svg/paypal-mark-color.svg')}
          style={{ height: '24px', width: '24px', marginRight: '8px' }}
          alt={buttonText}
        />
        {buttonText}
      </Button>
    );
  }
}

NextButtonComponent.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleNext: PropTypes.func,
};

export default NextButtonComponent;
