import React from 'react';
import CardSecurityCode from '../react-card-security-code';
import AppStore from '../stores/AppStore';
import * as AppActions from '../actions/AppActions';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

class CVVComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      securityCode: '',
      cardSpec: AppStore.getCardSpec().cardSpec,
      errorMessageCSC: '',
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleChangeSecurityCode(securityCode) {
    this.setState({ securityCode: securityCode });
    this.props.handleSecurityCodeChange(securityCode);
  }

  handleFieldFocus(fieldName) {
    this.setState({ [fieldName]: '' });
  }

  /*
   * Handle error message for selected field
   */
  handleFieldBlur(value, fieldName, fieldRequired, fieldInvalid, worldReady) {
    let newErrorState = {};

    if (value) {
      newErrorState = {
        [fieldName]: this.validateInputValue(
          value,
          fieldName,
          fieldInvalid,
          worldReady
        ),
      };
    } else {
      newErrorState = {
        [fieldName]: new MessageFormat(worldReady, {
          id: `errors.service.${fieldRequired}`,
        }).format(),
      };
    }

    this.setState(newErrorState);
  }

  /*
   * Validate input
   */
  validateInputValue(value, fieldName, fieldInvalid, worldReady) {
    let {
      cardSpec: {
        fields: { csc = {} },
      },
    } = this.state;

    if (fieldName === 'errorMessageCSC') {
      let cscLen = value && value.length;
      let cscMax = csc.maxlength;
      let cscMin = csc.minlength || cscMax;

      if (!(cscLen >= cscMin && cscLen <= cscMax)) {
        return new MessageFormat(worldReady, {
          id: `errors.service.${fieldInvalid}`,
        }).format();
      }
    }

    return '';
  }

  render() {
    const cscLabel = new MessageFormat(this.context, {
      id: 'guest.guestForm.label.csc',
    }).format();

    return (
      <div className="cvv_component" id="cvv_component">
        <div className="cvv_field">
          <CardSecurityCode
            showIsRequiredError={true}
            label={cscLabel}
            placeholder={new MessageFormat(this.context, {
              id: 'guest.guestForm.label.cscplaceholder',
            }).format()}
            errorMessage={this.state.errorMessageCSC}
            handleChangeSecurityCode={(value) =>
              this.handleChangeSecurityCode(value)
            }
            handleFocus={(value) => this.handleFieldFocus('errorMessageCSC')}
            handleBlur={(value) =>
              this.handleFieldBlur(
                value,
                'errorMessageCSC',
                'REQUIRED_SECURITY_CODE',
                'INVALID_SECURITY_CODE',
                this.context
              )
            }
            cardSpec={this.state.cardSpec}
            helperText={new MessageFormat(this.context, {
              id: 'guest.guestForm.label.cscnote',
            }).format({
              csc: cscLabel.toLowerCase(),
            })}
          />
        </div>
      </div>
    );
  }
}

CVVComponent.contextType = WorldReadyContext;

export default CVVComponent;
