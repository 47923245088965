import React, { Component } from 'react';
import AppStore from '../../stores/AppStore';
import _get from 'lodash/get';
import { getPaymentCardNetwork } from '../../utility/card-utils';
import { StyleSheet, css } from 'aphrodite';
import _isEmpty from 'lodash/isEmpty';
import { hideSpinnerOverlay } from '../../utility/helper';
import { appendCdnHostName } from '../../utility/geoUtils';
import withTheme from '../withTheme';
import { BodyText, HeadingText } from '@paypalcorp/pp-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

const LOADING_GIF_SOURCE = appendCdnHostName(
  '/ui-web/money-icons/partnerships/mbLoader_256_sm.gif'
);

const STYLES = StyleSheet.create({
  spinnerAlign: {
    margin: '30px auto 30px',
  },
  cardImg: {
    marginTop: '24px',
  },
});

class ThreeDSSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardImg: null,
      network: null,
    };
  }

  setNetworkDetails = () => {
    const fundingOptions = AppStore.getFundingOptions();
    let cardImg = _get(
      fundingOptions,
      'sources[0].payment_card.issuer.thumbnail_logo_url'
    );
    let network = getPaymentCardNetwork(
      _get(fundingOptions, 'sources[0].payment_card')
    );
    const myData = AppStore.getAllData() || {};
    if (
      cardImg === null ||
      _isEmpty(cardImg) ||
      typeof cardImg === 'undefined'
    ) {
      const cardType = _get(myData, 'cardSpec.cardSpec.type', '')
        .replace(/[.,_]|\s\(.*\)/g, '')
        .replace(/[\s_]/g, '-')
        .toLowerCase();
      cardImg = appendCdnHostName(
        `/digitalassets/c/consumer/p2p/funding-sources/${cardType}.png`
      );
    }
    if (
      network === null ||
      _isEmpty(network) ||
      typeof network === 'undefined'
    ) {
      network = myData.cardSpec.cardSpec.type.replace(/[_]/g, ' ');
    }
    this.setState({ cardImg, network });
  };

  componentDidMount() {
    // Hide the donate spinner in the background
    // to prevent double spinners
    hideSpinnerOverlay();
    this.setNetworkDetails();
  }

  render() {
    const worldReady = this.context;
    return (
      <div className="three-ds-spinner donatespinner">
        <div className="loadingcontent">
          <div className="text-center">
            {/* <div className={`spinner ${css(STYLES.spinnerAlign)}`}></div> */}
            <div id="threeDSSpinnerImg">
              <img src={LOADING_GIF_SOURCE} height="150" />
            </div>
            <HeadingText size="md" id="waitText">
              {new MessageFormat(worldReady, {
                id: 'common.threeDSecure.confirmingWith',
              }).format({
                network: this.state.network,
              })}
            </HeadingText>
            <div className={`${css(STYLES.cardImg)}`}>
              {this.state.cardImg ? (
                <img src={this.state.cardImg} alt="Card logo" />
              ) : (
                ''
              )}
            </div>
            <div id="spinnerText">
              <BodyText>
                {new MessageFormat(worldReady, {
                  id: 'common.threeDSecure.wontTakeLong',
                }).format()}
              </BodyText>
              <BodyText>
                {new MessageFormat(worldReady, {
                  id: 'common.threeDSecure.DoNotCloseOrRefresh',
                }).format()}
              </BodyText>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ThreeDSSpinner.contextType = WorldReadyContext;

export default withTheme(ThreeDSSpinner);
