import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CDN_HOST_NAME } from '../../../utility/constants';
import { BodyText, CaptionText } from '@paypalcorp/pp-react-text';
import { CreditCardIcon } from '@paypalcorp/pp-react-icons';
import _isEmpty from 'lodash/isEmpty';
import { Badge } from '@paypalcorp/pp-react-badge';
import { Message } from '@paypalcorp/worldready-react';

const PaymentInformation = ({
  source,
  paymentDigits,
  paymentType,
  issuer,
  isPaypalCurrencyConversion,
  funds,
  cardType,
}) => {
  const [paymentLogo, setPaymentLogo] = useState('');

  const transformText = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getIssuer = () => {
    return issuer?.toLowerCase().split(' ').map(transformText).join(' ');
  };

  useEffect(() => {
    let cardImg = source?.payment_card?.issuer?.thumbnail_logo_url ?? null;
    if (
      cardImg === null ||
      _isEmpty(cardImg) ||
      typeof cardImg === 'undefined'
    ) {
      cardImg = `${CDN_HOST_NAME}/digitalassets/c/consumer/p2p/funding-sources/${cardType}.png`;
    }
    setPaymentLogo(cardImg);
  }, []);

  return (
    <div className="cb-payment-information ppvx_justify-content-between">
      <div className="payment-info">
        {!_isEmpty(paymentLogo) ? (
          <img src={paymentLogo} className="payment-logo" aria-hidden="true" />
        ) : (
          <CreditCardIcon size="xl2" />
        )}
        <div className="pay-info">
          {issuer && <BodyText>{getIssuer()}</BodyText>}
          <CaptionText className="funding-info">
            <Message
              id="guest.crossBorder.label.paymentInformation"
              type={paymentType}
              number={paymentDigits}
            />
          </CaptionText>
          {!isPaypalCurrencyConversion && (
            <Badge type="success">
              <Message id="guest.crossBorder.label.preferred" />
            </Badge>
          )}
        </div>
      </div>
      <BodyText>{funds}</BodyText>
    </div>
  );
};

PaymentInformation.propTypes = {
  fundingOptions: PropTypes.object,
  paymentDigits: PropTypes.string,
  paymentType: PropTypes.string,
  issuer: PropTypes.string,
  funds: PropTypes.string,
  isPaypalCurrencyConversion: PropTypes.bool,
  source: PropTypes.object,
  cardType: PropTypes.string,
};

export default PaymentInformation;
