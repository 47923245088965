/*
    Regular order is: SELF > TOP > FIRST.
    For donations > 10, TOP should be shown, if is the same person as SELF or FIRST then display TOP only
    For donations <= 10, if SELF is the same person as FIRST then FIRST should displayed only
*/
export function formatFeatured(contributions, totalContributions) {
  const formattedList = [];

  if (contributions.length === 0) {
    return formattedList;
  }

  const first = contributions.find((c) => c.badge.toUpperCase() === 'FIRST');
  const top = contributions.find((c) => c.badge.toUpperCase() === 'TOP');
  const selfItem = contributions.find((c) => c.badge.toUpperCase() === 'SELF');

  if (selfItem) {
    if (selfItem?.id !== top?.id && selfItem?.id !== first?.id) {
      formattedList.push(selfItem);
    }
  }
  if (top && totalContributions > 10) {
    formattedList.push(top);
  }
  if (first) {
    if (first?.id !== top?.id) {
      formattedList.push(first);
    }
  }

  return formattedList;
}
