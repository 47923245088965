import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RECURRING_EXPERIMENT } from '../../../../lib/constants';
import { MessageFormat } from '@paypalcorp/worldready';
import { getWorldReady } from '../../utility/worldReadyUtils';
import RecurringCheckBox from './checkbox/recurringCheckbox';
import * as AppActions from '../../actions/AppActions';
import RecurringSegmentedController from './selectionGroup/segmentedController';
import RecurringSingleSelection from './selectionGroup/singleSelection';
import {
  trackLinkClick,
  trackRecurringFrequencySelectionClick,
} from '../../fpti/fpti';
import { FPTI_TAGS, LINK_NAME } from '../../fpti/fptiConstants';

const RecurringComponent = ({
  flow,
  recurringFrequencies = [],
  isCampaigns = false,
}) => {
  const [frequenciesContent, setFrequenciesContent] = useState([]);
  const worldReady = getWorldReady();

  useEffect(() => {
    const supportedFrequencies = [];

    if (isCampaigns) {
      const newFreq = {
        primaryText: new MessageFormat(worldReady, {
          id: `common.recurring-checkbox.rp.frequency.${RECURRING_EXPERIMENT.CONTENT_KEYS.MONTH}`,
        }).format(),
        value: 'MONTH',
      };

      supportedFrequencies.push(newFreq);

      setFrequenciesContent(supportedFrequencies);
      return;
    }

    const showOneTimeContent =
      flow ===
      RECURRING_EXPERIMENT.TREATENTS.Ctrl_Giving_donate_recurring_checkbox;

    recurringFrequencies.forEach((freq) => {
      if (showOneTimeContent) {
        if (freq !== 'ONE_TIME') {
          const newFreq = {
            primaryText: new MessageFormat(worldReady, {
              id: `common.recurring-checkbox.rp.frequency.${RECURRING_EXPERIMENT.CONTENT_KEYS[freq]}`,
            }).format(),
            value: freq,
          };
          supportedFrequencies.push(newFreq);
        }
      } else {
        const newFreq = {
          primaryText: new MessageFormat(worldReady, {
            id: `common.recurring-checkbox.rp.frequency.${RECURRING_EXPERIMENT.CONTENT_KEYS[freq]}`,
          }).format(),
          value: freq,
        };
        supportedFrequencies.push(newFreq);
      }
    });

    setFrequenciesContent(supportedFrequencies);
  }, []);

  const selectedFrequencyOption = (e, freq) => {
    let selectedRecurringFrequency = RECURRING_EXPERIMENT.VALUE[freq];
    AppActions.changeSelectedRecurringFrequency({ selectedRecurringFrequency });

    if (freq === 'ONE_TIME') {
      AppActions.changeRecurring({
        recurring: 'unchecked',
      });
    } else {
      AppActions.changeRecurring({
        recurring: 'checked',
      });
    }

    if (
      flow ===
      RECURRING_EXPERIMENT.TREATENTS.Ctrl_Giving_donate_recurring_checkbox
    ) {
      trackLinkClick(LINK_NAME.RECURRING_CHECKBOX, {
        additionalData: {
          [FPTI_TAGS.CHECKBOX]: freq === 'ONE_TIME' ? 'unchecked' : 'checked',
        },
      });
    } else {
      trackRecurringFrequencySelectionClick();
    }
  };

  const displayUI = () => {
    const props = {
      frequencies: frequenciesContent,
      handleFreqChange: selectedFrequencyOption,
    };

    switch (flow) {
      case RECURRING_EXPERIMENT.TREATENTS
        .Trmt_1_Giving_donate_segmented_controller:
        return <RecurringSegmentedController {...props} />;
      case RECURRING_EXPERIMENT.TREATENTS
        .Trmt_2_Giving_donate_selection_group_header_above:
        return <RecurringSingleSelection showTitle={false} {...props} />;
      case RECURRING_EXPERIMENT.TREATENTS
        .Trmt_3_Giving_donate_selection_group_header_bellow:
        return <RecurringSingleSelection showTitle={true} {...props} />;
      case RECURRING_EXPERIMENT.TREATENTS.Ctrl_Giving_donate_recurring_checkbox:
      default:
        return <RecurringCheckBox {...props} />; // CONTROL
    }
  };

  return displayUI();
};

RecurringComponent.propTypes = {
  flow: PropTypes.string,
};

export default RecurringComponent;
