import React from 'react';
import { Sheet, BodyText, Button, HeadingText } from '@paypalcorp/pp-react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import { Row, Col } from '@paypalcorp/pp-react';
import { appendCdnHostName } from '../utility/geoUtils';
import { Message } from '@paypalcorp/worldready-react';

const styles = StyleSheet.create({
  modalContent: {
    maxWidth: 600,
    margin: '0 auto',
  },
  okButton: {
    marginTop: 36,
  },
  msgMargin: {
    marginTop: '24px',
  },
});

export default function DonateGiftAidHowItWorksOverlay(props) {
  const { orgName } = props;

  return (
    <Sheet
      className="giftaid-howitworks-overpanel"
      overpanel
      title=""
      isOpen={props.isOpen}
      onClose={props.closeOverlay}
      hideLogo
      closeOnBackgroundClick={true}
    >
      <div className="modal-body" id="giftaiditModal">
        <div className="">
          <div className={`${css(styles.modalContent)} modal-content`}>
            <Row>
              <Col className="text-center mb-16">
                <img
                  className="image-wrapper"
                  src={appendCdnHostName(
                    '/webstatic/ppgf/giftaid/GiftAidWPS.png'
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <HeadingText size="sm">
                  <Message id="common.giftAidIt.giftAidIt.howitworks" />
                </HeadingText>
              </Col>
            </Row>
            <Row>
              <Col>
                <BodyText className={css(styles.msgMargin)}>
                  <Message
                    id="common.giftAidIt.giftAidIt.modalp1"
                    orgName={orgName}
                  />
                </BodyText>
              </Col>
            </Row>
            <Row>
              <Col>
                <BodyText className={css(styles.msgMargin)}>
                  <Message
                    id="common.giftAidIt.giftAidIt.modalp2"
                    orgName={orgName}
                  />
                </BodyText>
              </Col>
            </Row>
            <Row>
              <Col>
                <BodyText className={css(styles.msgMargin)}>
                  <Message id="common.giftAidIt.giftAidIt.modalp3" />
                </BodyText>
              </Col>
            </Row>
            <Row>
              <Col>
                <BodyText className={css(styles.msgMargin)}>
                  <Message id="common.giftAidIt.giftAidIt.modalp4" />
                </BodyText>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className={`${css(styles.okButton)} text-center`}>
                  <Button className="btn-wide" onClick={props.closeOverlay}>
                    <Message id="common.giftAidIt.giftAidIt.ok" />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Sheet>
  );
}

DonateGiftAidHowItWorksOverlay.propTypes = {
  orgName: PropTypes.string,
  isOpen: PropTypes.bool,
  closeOverlay: PropTypes.func,
};
