import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@paypalcorp/pp-react';
import { Message } from '@paypalcorp/worldready-react';

class OneTouchNextButton extends React.Component {
  render() {
    return (
      <Button
        className="btn-wide"
        id="payWithPP"
        disabled={this.props.disabled}
        onClick={this.props.handleNext}
      >
        <Message id="common.oneTouch.button.next" />
      </Button>
    );
  }
}

OneTouchNextButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleNext: PropTypes.func,
};

export default OneTouchNextButton;
