import React, { Suspense, useEffect, useState, useContext } from 'react';
import templateData from '../../utility/getTemplateData';
import PPCC from '../../components/ppcc/ppcc';
import {
  Card,
  Col,
  Button,
  Sheet,
  LoadingSpinner,
  BodyText,
  HeadingText,
} from '@paypalcorp/pp-react';
import classNames from 'classnames';

import {
  GoalProgressMeter,
  useHasScrolledToPosition,
  CampaignImage,
  Title,
  AdvancedEndDateBadge,
} from '@paypalcorp/donate-react';
import { hideSpinnerOverlay } from '../../utility/helper';
import Landing from './landing';
import withTheme from '../../components/withTheme';
import {
  roundUpAndFormatCurrency,
  parseDateOnly,
} from '../../utility/formator';
import { lazy as reactLazy } from 'react';
import { addClass } from '../../utility/domUtils';
import WhosDonatedBanner from '../../components/WhosDonatedBanner';
import Flyout from '../../components/Flyout';
import { fetcher } from '../../utility/domUtils';
import { formatFeatured } from '../../utility/donorWall';
import ConfirmationSocialShare from '../../confirmation/ConfirmationSocialShare';
import {
  trackDonateLandingButtonClick,
  trackUpdatesReadMorePressed,
} from '../../fpti/fpti';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { Message } from '@paypalcorp/worldready-react';
import { useIsMobile } from '../../utility/isMobile';

const LandingDonorWall = reactLazy(() =>
  import(
    /* webpackChunkName: "LandingDonorWall" */ '../../components/LandingDonorWall'
  )
);

const LandingRichTextDisplay = reactLazy(() =>
  import(
    /* webpackChunkName: "LandingRichTextDisplay" */ '../../components/LandingRichTextDisplay'
  )
);

const OrganizerUpdates = reactLazy(() =>
  import(
    /* webpackChunkName: "LandingOrgUpdates" */ '../../components/organizerUpdates/OrganizerUpdates'
  )
);

const MAX_HEIGHT_BANNER = 380;
const MAX_HEIGHT_DESCRIPTION = 800;
const MAX_VISIBLE_TRANSACTION_PAGES = 100;

function isCampaignEnded(endDate) {
  return (
    endDate && new Date().setHours(0, 0, 0, 0) > endDate?.setHours(0, 0, 0, 0)
  );
}

const CampaignLanding = () => {
  const worldReady = useContext(WorldReadyContext);
  const [isPaymentCardOpen, setIsPaymentCardOpen] = useState(false);
  const amountRaised = templateData.campaignTotalAmountRaised || '0';
  const campaignStatus = templateData.campaignStatus;
  const targetAmount = templateData.campaignTargetAmount;
  const campaignEndTime = templateData.campaignEndTime;
  const campaignTotalNumberOfTxn = templateData.campaignTotalNumberOfTxn;
  const amountRaisedWithCurrencySymbol = roundUpAndFormatCurrency(
    amountRaised,
    templateData.donationCode
  );
  const donationName = templateData.donationName ?? '';
  const [contributionsList, setContributionsList] = useState({
    featured: [],
    list: [],
  });
  const [contributionsError, setContributionsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [retry, setRetry] = useState(0);
  const [enableReadMore, setEnableReadMore] = useState(true);
  const [enableReadMoreUpdate, setEnableReadMoreUpdate] = useState(true);
  const [descriptionHeight, setDescriptionHeight] = useState(
    MAX_HEIGHT_DESCRIPTION
  );
  const [updateHeight, setUpdateHeight] = useState(MAX_HEIGHT_DESCRIPTION);
  const [originalDescriptionHeight, setOriginalDescriptionHeight] = useState(0);
  const [newUpdateHeight, setNewUpdateHeight] = useState(0);

  const stickToTop = useHasScrolledToPosition(650);

  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetcher(
        `/donate/api/donorwall/${templateData.productId}/${currentPage}/10`
      ).then((response) => response.json());
      if (result.contributions) {
        setContributionsList({
          featured:
            contributionsList.featured.length !== 0
              ? contributionsList.featured
              : formatFeatured(
                  result?.contributions?.contributions?.featured,
                  campaignTotalNumberOfTxn
                ),
          list: [
            ...contributionsList.list,
            ...(result?.contributions?.contributions?.list ?? []),
          ],
        });
      } else {
        setContributionsError(true);
      }
      setIsLoading(false);
    };

    setIsLoading(true);
    setContributionsError(false);
    fetchData();
  }, [currentPage, retry]);

  const handleShowMore = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    addClass('#mainWrapper header.donate_global_nav', ['hide-header']);
    addClass('#donateWrapper', ['is-landing']);
    hideSpinnerOverlay();
  }, []);

  const goalAmtWithCurrencySymbol = roundUpAndFormatCurrency(
    targetAmount,
    templateData.donationCode
  );

  const campaignGoalTitle = new MessageFormat(worldReady, {
    id: 'common.campaigns.campaignGoal.title',
  }).format({
    goalAmount: goalAmtWithCurrencySymbol,
  });

  const getCampaignMessage = (id, formatObj = undefined) =>
    new MessageFormat(worldReady, {
      id: `landing.campaigns.${id}`,
    }).format(formatObj);

  const advancedEndDateBadgeContent = {
    minute: getCampaignMessage('minute'),
    minutes: getCampaignMessage('minutes'),
    hour: getCampaignMessage('hour'),
    hours: getCampaignMessage('hours'),
    day: getCampaignMessage('day'),
    days: getCampaignMessage('days'),
    month: getCampaignMessage('month'),
    months: getCampaignMessage('months'),
    year: getCampaignMessage('year'),
    years: getCampaignMessage('years'),
    endsInContent: getCampaignMessage('endsInContent', {
      duration: '{duration}',
      unit: '{unit}',
    }),
    endedContent: getCampaignMessage('endedContent', { date: '{date}' }),
  };

  const renderEndDateBadge = () => (
    <div className="wrapper-badge">
      <AdvancedEndDateBadge
        campaignStatus={campaignStatus}
        endTime={new Date(campaignEndTime)}
        content={advancedEndDateBadgeContent}
        currentTime={new Date()}
        formatDate={parseDateOnly}
      />
    </div>
  );

  const renderTitle = (size) => (
    <div className="wrapper-title">
      <Title size={size} title={templateData.campaignTitle} />
    </div>
  );

  const renderGoalProgressMeter = () => (
    <div className="wrapper-progress-meter">
      <GoalProgressMeter
        max={targetAmount}
        value={Math.round(amountRaised).toString()}
        steps={amountRaisedWithCurrencySymbol}
        title={campaignGoalTitle}
        className="goal-progress-meter"
      />
    </div>
  );

  const renderAvatarStack = () => (
    <div className="wrapper-avatar-stack">
      <WhosDonatedBanner
        numberOfContributors={campaignTotalNumberOfTxn}
        contributionsList={contributionsList.list}
        isLoading={isLoading}
      />
    </div>
  );

  const renderDonationName = () => (
    <div className="wrapper-donation-name">
      <BodyText>
        {donationName}
        <PPCC />
      </BodyText>
    </div>
  );

  const headerClasses = classNames({
    'preview-header': true,
    sticky: stickToTop,
  });

  const renderHeader = () => (
    <div className="wrapper-header">
      {renderEndDateBadge()}
      {renderTitle('lg')}
      {renderAvatarStack()}
      {renderDonationName()}
      {renderGoalProgressMeter()}
    </div>
  );

  const renderDonorWall = () => {
    return (
      <>
        <LandingDonorWall
          contributionsList={contributionsList}
          isLoading={isLoading}
          currentPage={currentPage}
          handleShowMore={handleShowMore}
          totalNumberOfTransactions={campaignTotalNumberOfTxn}
          paginationLimit={MAX_VISIBLE_TRANSACTION_PAGES}
        />
        {campaignStatus === 'ACTIVE' &&
        !isCampaignEnded(new Date(campaignEndTime)) &&
        contributionsList?.list?.length >= 10 ? (
          <Flyout contributionsList={contributionsList.list} />
        ) : null}
      </>
    );
  };

  const renderContributionsError = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <BodyText>
          <Message id="landing.campaigns.contributions.error.title" />
        </BodyText>
        <BodyText style={{ color: '#515354' }}>
          <Message id="landing.campaigns.contributions.error.body" />
        </BodyText>
        <Button
          tertiary
          onClick={() => {
            setRetry(retry + 1);
          }}
        >
          <Message id="landing.campaigns.contributions.error.cta" />
        </Button>
      </div>
    );
  };

  const renderContributions = () => {
    if (!contributionsError) {
      return renderDonorWall();
    } else if (currentPage === 1) {
      return renderContributionsError();
    }

    return (
      <>
        {renderDonorWall()}
        {renderContributionsError()}
      </>
    );
  };

  const stickyHeader = stickToTop && (
    <div className={headerClasses}>
      <div className="wrapper-header">
        {renderEndDateBadge()}
        {renderTitle('sm')}
        {renderGoalProgressMeter()}
      </div>
    </div>
  );

  const personalizedMessages = {
    social_title: new MessageFormat(worldReady, {
      id: 'landing.social.subheader',
    }).format(),
  };

  const getSocialMessage = (id, params) => {
    return new MessageFormat(worldReady, {
      id: `landing.social.${id}`,
    }).format(params);
  };

  function buildSocialMessages() {
    return {
      social: {
        emailSubject: getSocialMessage('emailSubject', {
          donationName: '{donationName}',
        }),
        emailBody: getSocialMessage('emailBody', {
          donationName: '{donationName}',
          url: '{url}',
        }),
        mobileText: getSocialMessage('mobileText', {
          donationName: '{donationName}',
        }),
        facebookMessage: getSocialMessage('facebookMessage', {
          donationName: '{donationName}',
          url: '{url}',
        }),
        twitterMessage: getSocialMessage('twitterMessage', {
          donationName: '{donationName}',
        }),
        whatsappMessage: getSocialMessage('whatsappMessage', {
          donationName: '{donationName}',
        }),
        copyLinkHeader: getSocialMessage('copyLinkHeader'),
        copyLinkSubheaderBefore: getSocialMessage('copyLinkSubheaderBefore'),
        copyLinkSubheader: getSocialMessage('copyLinkSubheader'),
        copiedText: getSocialMessage('copiedText'),
        copyText: getSocialMessage('copyText'),
        subheader: getSocialMessage('subheader'),
        shareGeneric: getSocialMessage('shareGeneric'),
      },
    };
  }

  const socialContent = () => {
    return (
      <div className="social-share-wrapper">
        <HeadingText
          size="sm"
          style={{ textAlign: `${!isMobile ? 'left' : 'center'}` }}
        >
          <Message id="landing.social.shareGeneric" />
        </HeadingText>
        <ConfirmationSocialShare
          campaignId={templateData.productId}
          messages={buildSocialMessages()}
          personalizedMessages={personalizedMessages}
          email={templateData.donationEmail}
          orgName={templateData.charityName}
          isLanding={true}
        />
      </div>
    );
  };

  const incrementDescription = (event) => {
    event.preventDefault();
    setDescriptionHeight((height) => {
      const incrementedHeight = height + MAX_HEIGHT_DESCRIPTION;
      if (incrementedHeight >= originalDescriptionHeight)
        setEnableReadMore(false);
      return incrementedHeight;
    });
  };

  const incrementUpdate = (event) => {
    trackUpdatesReadMorePressed();
    event.preventDefault();
    setUpdateHeight((height) => {
      const incrementedHeight = height + MAX_HEIGHT_DESCRIPTION;
      if (incrementedHeight >= newUpdateHeight) {
        setEnableReadMoreUpdate(false);
      }
      return incrementedHeight;
    });
  };

  const renderReadMore = () =>
    originalDescriptionHeight >= MAX_HEIGHT_DESCRIPTION &&
    enableReadMore && (
      <div className="campaign-read-more">
        <Button tertiary onClick={incrementDescription}>
          <Message id="landing.campaigns.readMore" />
        </Button>
      </div>
    );

  const renderReadMoreUpdate = () => {
    if (newUpdateHeight >= MAX_HEIGHT_DESCRIPTION && enableReadMoreUpdate) {
      return (
        <div className="campaign-read-more">
          <Button tertiary onClick={incrementUpdate}>
            <Message id="landing.campaigns.readMore" />
          </Button>
        </div>
      );
    }
  };

  return (
    <div className="campaign-landing-container">
      <CampaignImage
        alt=""
        className="campaign-landing-banner"
        src={templateData.bannerUrl}
      />
      {!isMobile && stickyHeader}
      <div className="content-container">
        <div className="campaignDetails" data-testid="campaignDetails">
          <Card.Content>
            <div className="campaign-title">{renderHeader()}</div>
            <div
              className="campaign-description"
              style={{
                maxHeight:
                  originalDescriptionHeight >= MAX_HEIGHT_DESCRIPTION
                    ? descriptionHeight + 'px'
                    : 'unset',
              }}
            >
              <div
                ref={(descriptionContainer) =>
                  descriptionContainer &&
                  setOriginalDescriptionHeight(
                    descriptionContainer.clientHeight
                  )
                }
              >
                <Suspense
                  fallback={
                    <LoadingSpinner size="md" style={{ margin: '0 auto' }} />
                  }
                >
                  <LandingRichTextDisplay
                    campaignDescription={templateData.campaignDescription}
                  />
                </Suspense>
              </div>
              {renderReadMore()}
            </div>
            <Suspense
              fallback={
                <LoadingSpinner size="md" style={{ margin: '120px auto' }} />
              }
            >
              <OrganizerUpdates />
            </Suspense>
            <Suspense
              fallback={
                <LoadingSpinner size="md" style={{ margin: '120px auto' }} />
              }
            >
              {renderContributions()}
            </Suspense>
            {campaignTotalNumberOfTxn > 0 && socialContent()}
          </Card.Content>
        </div>
        {!isMobile ? (
          <div className="paymentCard">
            <Landing />
          </div>
        ) : (
          <>
            <Col lg="12" className="mobileDonate">
              <Button
                onClick={() => {
                  setIsPaymentCardOpen(!isPaymentCardOpen);
                  trackDonateLandingButtonClick({ noteFieldDisplayed: null });
                }}
              >
                <Message id="landing.campaigns.donateButton" />
              </Button>
            </Col>
            <Sheet
              isOpen={isPaymentCardOpen}
              onClose={() => {}}
              title={new MessageFormat(worldReady, {
                id: 'landing.campaigns.donateButton',
              }).format()}
              mobileFixedHeight="90"
              className="mobilePreview darkBackground"
              wrapperClassName="mobilePreview-wrapper"
              skipFormFocus
            >
              <Landing />
            </Sheet>
          </>
        )}
      </div>
    </div>
  );
};

export default withTheme(CampaignLanding);
