import React, { Component } from 'react';
import templateData from '../../utility/getTemplateData';
import {
  handleGuestCountryChange,
  hideSpinnerOverlay,
  showSpinnerOverlay,
} from '../../utility/helper';
import * as DonateAppActions from '../../actions/AppActions';
import { getInitialData } from '../../utility/formator';
import AppStore from '../../stores/AppStore';
import { getParametersFromUrl } from '../../utility/queryStringHelper';
import PropTypes from 'prop-types';
import { startCustomCPLTrackingForConfirmationPage } from '../../utility/cpl';
import {
  fetcher,
  scrollElementIntoView,
  objectOrArrayToQueryString,
} from '../../utility/domUtils';
import isEmpty from 'lodash/isEmpty';
import { trackLinkClick } from '../../fpti/fpti';
import { FPTI_TAGS, LINK_NAME } from '../../fpti/fptiConstants';
import { MessageFormat } from '@paypalcorp/worldready';
import { getWorldReady } from '../../utility/worldReadyUtils';

const initialData = getInitialData();

const ERROR_ELEMENT_SELECTOR = [
  '.vx_has-error-with-message',
  '.service_error_holder',
].join();

function withDonate(WrappedComponent) {
  const worldReady = getWorldReady();

  return class extends Component {
    static propTypes = {
      history: PropTypes.object,
    };

    constructor(props) {
      super(props);
      this.country = initialData.selectedCountry;
    }

    handleCountryChange = (country) => {
      if (country !== this.country) {
        this.toggleSpinner(true);
        DonateAppActions.changeCountry({
          country: country,
        });
        this.country = country;

        // Beacons for country change
        trackLinkClick(LINK_NAME.CHANGE_COUNTRY, {
          additionalData: {
            [FPTI_TAGS.COUNTRY]: country,
          },
        });

        const { token, signUp = false } = templateData;
        const url = signUp
          ? `/donate?token=${token}&signup=true&country.x=${country}`
          : `/donate?token=${token}&country.x=${country}&countryChange=true&clientState=/donate/guest?token=${token}`;

        handleGuestCountryChange(url);
      }
    };

    /*
     * showing and hiding spinner
     */
    toggleSpinner = (isSpinner = false) => {
      if (isSpinner) {
        showSpinnerOverlay();
      } else {
        hideSpinnerOverlay();
      }
    };

    // handles scrolling screen to the error
    handleScrollToError = () => {
      scrollElementIntoView(ERROR_ELEMENT_SELECTOR);
      return;
    };

    getDonateGuestUrl = () => {
      const { token, country, language } = templateData;

      let url = `/donate/guest?token=${token}`;
      if (country) {
        url = url + `&country.x=${country}`;
      }

      if (country && language) {
        url = url + `&locale.x=${country}_${language}`;
      }

      return url;
    };

    getFormData() {
      return AppStore.getFormData();
    }

    saveFormData(formData) {
      DonateAppActions.saveFormData(formData);
    }

    showErrorOnLoad() {
      const url = window.location.href;
      const params = getParametersFromUrl(url);

      if (params.showErrorOnLoad) {
        if (params.showErrorOnLoad === 'fulfillment') {
          // TODO: update error code to the actual fullfilment error code
          const errorCode = 'WSM_TRANSACTION_FAILED_DUE_TO_INVALID_CARD_DATA';
          const errorMsgs = [
            {
              key: 0,
              value: new MessageFormat(worldReady, {
                id: `errors.service.${errorCode}`,
              }).format(),
            },
          ];

          // adding delay to wait for the dispatcher to get registered in ServerErrors
          setTimeout(
            () =>
              DonateAppActions.updateServerErrors({ serverErrors: errorMsgs }),
            250
          );
        }
        // delete params.showErrorOnLoad;

        // const query = $.param(params);
        // const cleanedUrl = `/donate/guest/?${query}`;
        // window.history.replaceState({}, document.title, cleanedUrl);
      }
    }

    doFulfilment = (params = {}) => {
      const { securityCode } = params;
      this.toggleSpinner(true);

      fetcher('/donate/guest/fullfilment', {
        method: 'POST',
        body: JSON.stringify({
          token: templateData.token,
          note: AppStore.getDonationNote(),
          fundingOptionId: AppStore.getFundingOptions().id,
          email: AppStore.getEmail(),
          billingAddress: AppStore.getBillingAddress(),
          giftAidItFlag: AppStore.getGiftaidItFlagStatus() || false,
          card: { securityCode },
          onboardingEnabled: templateData.onboardingEnabled,
          selectedRecurringFrequency: AppStore.getSelectedRecurringFrequency(),
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 'WSM_PAYMENT_DENIED') {
            this.toggleSpinner(false);
            this.props.history.push(
              `/donate/error/payment?token=${templateData.token}`
            );
            return;
          } else if (result.status === 'redirect') {
            this.toggleSpinner(false);
            window.location.href = result.url;
            return;
          } else if (
            result.code === 'WSM_TRANSACTION_FAILED_DUE_TO_INVALID_CARD_DATA'
          ) {
            // fulfillment error
            const { token, country } = templateData;

            const clientState = encodeURIComponent(
              `/donate/guest?token=${token}&showErrorOnLoad=fulfillment`
            );
            window.location.href = `/donate?token=${token}&country.x=${country}&locale.x=${country}&clientState=${clientState}`;
            return;
          } else if (result.data && result.data.confirmationData) {
            startCustomCPLTrackingForConfirmationPage();
            this.toggleSpinner(false);
            const { token } = templateData;
            const confirmationData = result.data.confirmationData || {};
            const isPending =
              confirmationData.isOfacPending ||
              confirmationData?.postBackData?.payment_status === 'Pending';
            const { selectedRecurringFrequency } = confirmationData;
            const queryParams = objectOrArrayToQueryString({
              token,
              selectedRecurringFrequency,
            });

            DonateAppActions.updateConfirmation({
              confirmation: confirmationData,
            });
            if (isPending) {
              this.props.history.push(`/donate/guest/pending?${queryParams}`);
            } else {
              this.props.history.push(
                `/donate/guest/confirmation?${queryParams}`
              );
            }
          } else {
            this.toggleSpinner(false);
            this.props.history.push(
              `/donate/error/payment?token=${templateData.token}`
            );
          }
        })
        .catch(() => {
          this.toggleSpinner(false);
          this.props.history.push(
            `/donate/error/payment?token=${templateData.token}`
          );
        });
    };

    createOrder = (params) => {
      const token = templateData.token;

      fetcher(`/donate/orders?dtoken=${token}`, {
        method: 'POST',
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((result) => {
          if (!isEmpty(result.cartUrl)) {
            window.location.href = result.cartUrl;
          } else {
            this.props.history.push(
              `/donate/error/payment?token=${templateData.token}`
            );
          }
        })
        .catch(() => {
          this.toggleSpinner(false);
          this.props.history.push(
            `/donate/error/payment?token=${templateData.token}`
          );
        });
    };

    captureOrder = (orderToken, donateToken, payerID) => {
      return fetcher(
        `/donate/orders/${orderToken}/capture?dtoken=${donateToken}&PayerID=${payerID}`,
        {
          method: 'POST',
        }
      );
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleCountryChange={this.handleCountryChange}
          toggleSpinner={this.toggleSpinner}
          handleScrollToError={this.handleScrollToError}
          getDonateGuestUrl={this.getDonateGuestUrl}
          getInitialFormData={this.getInitialFormData}
          getFormData={this.getFormData}
          saveFormData={this.saveFormData}
          showErrorOnLoad={this.showErrorOnLoad}
          doFulfilment={this.doFulfilment}
          captureOrder={this.captureOrder}
          createOrder={this.createOrder}
        />
      );
    }
  };
}

export default withDonate;
