import React, { Component } from 'react';
import AppStore from '../../stores/AppStore';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { trackThreeDsDdcFailure } from '../../fpti/fpti';
import { FPTI_TAGS } from '../../fpti/fptiConstants';

class ThreeDSDeviceDataCollection extends Component {
  static propTypes = {
    onThreeDSDDCComplete: PropTypes.func,
  };

  state = {
    resolve: false,
  };

  getThreeDSParams() {
    const fundingOptions = AppStore.getFundingOptions();
    const contingencies = _get(fundingOptions, 'contingencies') || [];
    return (
      _find(contingencies, { action: '3D_SECURE_DATA_COLLECTION_REQUIRED' }) ||
      {}
    );
  }

  componentDidMount() {
    // 'message' is passed after 3DS Submit is completed
    window.addEventListener('message', this.handleDDCEvent);

    const { three_ds_reference_id: threeDS2ReferenceId } =
      this.getThreeDSParams();

    if (this.refs[threeDS2ReferenceId]) {
      try {
        this.refs[threeDS2ReferenceId].submit();
        setTimeout(() => {
          if (!this.state.resolve) {
            trackThreeDsDdcFailure({
              [FPTI_TAGS.STATUS]: '3ds timeout',
            });
            this.onDDCComplete(
              { MessageType: 'profile.completed', Status: true },
              false
            );
          }
        }, 5000);
      } catch (e) {
        return;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleDDCEvent);
  }

  validateEventOrigin = (eventOrigin) => {
    return (
      eventOrigin &&
      (eventOrigin.includes('cardinalcommerce.com') ||
        eventOrigin.includes('paypal.com'))
    );
  };

  handleDDCEvent = (event) => {
    const eventOrigin = _get(event, 'origin', '');

    if (this.validateEventOrigin(eventOrigin)) {
      let data = null;
      try {
        data = JSON.parse(event.data);
      } catch (exception) {
        // Error handler incase parsing goes wrong
        data = {};
      }

      const messageType = _get(data, 'MessageType', '');
      if (messageType === 'profile.completed') {
        this.onDDCComplete(event);
      } else {
        // DDC not completed with message type
        trackThreeDsDdcFailure({
          [FPTI_TAGS.STATUS]: messageType,
        });
      }
    }
  };

  onDDCComplete = (event, wasSuccessful = true) => {
    this.setState({ resolve: true }, () =>
      this.props.onThreeDSDDCComplete(event, wasSuccessful)
    );
  };

  render() {
    const {
      device_data_collection_url: deviceDataCollectionUrl,
      jwt,
      three_ds_reference_id: threeDS2ReferenceId,
    } = this.getThreeDSParams();

    if (!deviceDataCollectionUrl) {
      return null;
    }

    return (
      <div>
        <iframe height="1" width="1" name="deviceData" />
        <form
          ref={threeDS2ReferenceId}
          method="POST"
          name="deviceData"
          target="deviceData"
          action={deviceDataCollectionUrl}
        >
          <input type="hidden" name="JWT" data-testid="jwt" value={jwt} />
        </form>
      </div>
    );
  }
}

export default withRouter(ThreeDSDeviceDataCollection);
