import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as AppActions from '../../actions/AppActions';
import { Checkbox, Tooltip } from '@paypalcorp/pp-react';
import { EVENT_NAME } from '../../utility/constants';
import { trackLinkClick } from '../../fpti/fpti';
import { FEATURES, LINK_NAME } from '../../fpti/fptiConstants';
import { addFeature } from '../../fpti/helper';

const AnonymousCheckbox = ({
  content,
  optInStatus,
  handleAnonymousDonationChange,
}) => {
  useEffect(() => {
    addFeature(FEATURES.ANONYMOUS);
  }, []);

  const handleChange = (event) => {
    const isChecked = event.target.checked,
      checkboxStatus = isChecked ? 'checked' : 'unchecked';

    AppActions.changeAnonymousStatus({
      anonymousStatus: checkboxStatus,
    });

    handleAnonymousDonationChange(isChecked);
  };

  const trackTooltipOpen = () => {
    trackLinkClick(LINK_NAME.ANONYMOUS_TOOLTIP, {
      eventName: EVENT_NAME.DW_GIVING_DONATE_SETUP_TOOLTIP_PRESSED,
    });
  };

  return (
    <div
      className={`anonymous-checkbox-wrapper ${
        content?.tooltip ? 'with-tooltip' : ''
      }`}
    >
      <Checkbox
        className="anonymous-checkbox"
        id="anonymous-checkbox"
        data-testid="anonymous-checkbox"
        name="anonymousCheckbox"
        label={content?.label}
        value={optInStatus}
        onChange={handleChange}
        checked={optInStatus}
        labelIcon={
          content?.tooltip && (
            <Tooltip
              name="anonymous-checkbox-tooltip"
              className="anonymous-checkbox-tooltip"
              description={content?.tooltip}
              onOpen={trackTooltipOpen}
            />
          )
        }
      />
    </div>
  );
};

AnonymousCheckbox.propTypes = {
  content: PropTypes.object,
  optInStatus: PropTypes.bool,
  handleAnonymousDonationChange: PropTypes.func,
};

export default AnonymousCheckbox;
