import { useEffect, useState } from 'react';
import { MAX_MOBILE_WIDTH } from '../setup/clientConstants';
import { throttle } from 'lodash';

const getIsMobile = () => window.innerWidth < MAX_MOBILE_WIDTH;

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    const throttledFn = throttle(onResize, 200);

    window.addEventListener('resize', throttledFn);

    return () => {
      window.removeEventListener('resize', throttledFn);
    };
  });

  return isMobile;
}
