import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CaptionText } from '@paypalcorp/pp-react';
import { Message } from '@paypalcorp/worldready-react';
import TemplateData from '../../utility/getTemplateData';
import { sanitizeOrgName } from '../../utility/domUtils';
import { RECURRING_EXPERIMENT } from '../../../../lib/constants';
import AppStore from '../../stores/AppStore';
import { normalizeNumber, formatAmount } from '../../utility/formator';

export default function RecurringInfo() {
  const orgname = TemplateData.donationName
    ? sanitizeOrgName(TemplateData.donationName)
    : '';
  const [rpContentHelper, setRpContentHelper] = useState('');
  const [amout, setAmount] = useState('');

  useEffect(() => {
    const frequency = AppStore.getSelectedRecurringFrequency();

    setRpContentHelper(
      `${RECURRING_EXPERIMENT.DISCLAIMER_CONTENT}${frequency}`
    );
  }, [AppStore.getSelectedRecurringFrequency()]);

  useEffect(() => {
    const optInStatus = AppStore.getCoverFeeOptInStatus();

    let totalAmount = 0;

    if (optInStatus !== undefined && optInStatus === 'checked') {
      totalAmount =
        +Number(AppStore.getDonationAmount()) +
        normalizeNumber(AppStore.getFeeAmount());
    } else {
      totalAmount = +Number(AppStore.getDonationAmount());
    }

    const formattedTotalAmount = formatAmount({
      currency: TemplateData.donationCode,
      value: totalAmount,
    });

    setAmount(formattedTotalAmount);
  }, [AppStore.getDonationAmount(), AppStore.getCoverFeeOptInStatus()]);

  return (
    <CaptionText className="recurring-info">
      <Message
        id={`common.recurring-checkbox.rp.${rpContentHelper}`}
        orgName={orgname}
        amount={amout}
      />
    </CaptionText>
  );
}

RecurringInfo.propTypes = {
  orgName: PropTypes.string,
  amount: PropTypes.string,
};
