import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import ThreeDSSpinner from './ThreeDSSpinner';
import ThreeDSDeviceDataCollection from './ThreeDSDeviceDataCollection';
import ThreeDSIframe from './ThreeDSIframe';
import * as AppActions from '../../actions/AppActions';
import _get from 'lodash/get';
import { fetcher } from '../../utility/domUtils';
import { FPTI_TAGS } from '../../fpti/fptiConstants';
import templateData from '../../utility/getTemplateData';
import {
  trackThreeDsDdcComplete,
  trackThreeDsDdcStart,
  trackThreeDsLookupComplete,
  trackThreeDsLookupStart,
} from '../../fpti/fpti';

class ThreeDSContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showThreeDSIframe: false,
      showThreeDSSpinner: false,
      showThreeDSDeviceDataCollection: false,
      threeDSDCCStartTime: null,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  initializeThreeDS() {
    const startTime = new Date().getTime();
    this.setState({
      showThreeDSDeviceDataCollection: true,
      showThreeDSSpinner: true,
      threeDSDCCStartTime: startTime,
    });

    const fptiData = {
      [FPTI_TAGS.THREE_DS_DCC_START_TIME]: startTime,
      [FPTI_TAGS.THREE_DS_SPINNER_START_TIME]: startTime,
    };
    trackThreeDsDdcStart(fptiData);
  }

  handleThreeDSDDCComplete = () => {
    this.setState({
      showThreeDSDeviceDataCollection: false,
    });

    const threeDSDCCEndTime = new Date().getTime();
    const fptiData = {
      [FPTI_TAGS.THREE_DS_DCC_START_TIME]: this.state.threeDSDCCStartTime,
      [FPTI_TAGS.THREE_DS_DCC_END_TIME]: threeDSDCCEndTime,
      [FPTI_TAGS.THREE_DS_SPINNER_START_TIME]: this.state.threeDSDCCStartTime,
      [FPTI_TAGS.THREE_DS_SPINNER_END_TIME]: threeDSDCCEndTime,
    };
    trackThreeDsDdcComplete(fptiData);
    this.resolveThreeDSContingency();
  };

  resolveThreeDSContingency = () => {
    trackThreeDsLookupStart({
      [FPTI_TAGS.THREE_DS_LOOKUP_START_TIME]: new Date().getTime(),
    });

    fetcher('/donate/guest/resolve-threeds-contingency', {
      method: 'POST',
      body: JSON.stringify({
        token: templateData.token,
        onboardingEnabled: templateData.onboardingEnabled,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.hideThreeDSSpinner();

        const threeDSContingency = _get(result, 'data.threeDSContingency');
        const resolved = _get(threeDSContingency, 'threeDSContingencyResolved');

        trackThreeDsLookupComplete({
          timestamp: {
            [FPTI_TAGS.THREE_DS_LOOKUP_END_TIME]: new Date().getTime(),
          },
          [FPTI_TAGS.CONTINGENCY_RESOLVED]: resolved,
          [FPTI_TAGS.URL]: _get(
            threeDSContingency,
            'threeDS2Contingency.3d_secure_redirect_link'
          ),
          [FPTI_TAGS.ACTION]: _get(
            threeDSContingency,
            'threeDS2Contingency.action'
          ),
        });

        if (resolved) {
          // 3DS Contingency resolved without StepUp Auth
          this.props.onThreeDSStepUpAuthNotRequired();

          return;
        }
        // Save threeDS data and show 3DS iframe
        AppActions.updateThreeDSData(threeDSContingency);
        this.showThreeDSIframe();
      })
      .catch((e) => {
        // Clear 3DS data
        AppActions.updateThreeDSData({});
        this.hideThreeDSIframe();
        this.props.onThreeDSFailure();
      });
  };

  handleThreeDSStepUpAuthComplete = ({ confirmationStatus, error }) => {
    if (confirmationStatus && confirmationStatus === 'threeDS-success') {
      this.props.onThreeDSStepUpAuthSuccess();
    } else {
      this.props.onThreeDSStepUpAuthFailure();
      return;
    }
  };

  showThreeDSIframe = () => {
    this.setState({
      showThreeDSIframe: true,
    });
  };

  hideThreeDSIframe = () => {
    this.setState({
      showThreeDSIframe: false,
    });
  };

  showThreeDSSpinner = () => {
    this.setState({
      showThreeDSSpinner: true,
    });
  };
  hideThreeDSSpinner = () => {
    this.setState({
      showThreeDSSpinner: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.showThreeDSSpinner && (
          <Portal>
            <ThreeDSSpinner />
          </Portal>
        )}

        {this.state.showThreeDSDeviceDataCollection && (
          <ThreeDSDeviceDataCollection
            onThreeDSDDCComplete={this.handleThreeDSDDCComplete}
          />
        )}

        {this.state.showThreeDSIframe && (
          <ThreeDSIframe
            onStepUpAuthComplete={this.handleThreeDSStepUpAuthComplete}
            isOpen={true}
            hideModal={this.hideThreeDSIframe}
          />
        )}
      </div>
    );
  }
}

ThreeDSContainer.propTypes = {
  onThreeDSStepUpAuthNotRequired: PropTypes.func,
  onThreeDSStepUpAuthSuccess: PropTypes.func,
  onThreeDSStepUpAuthFailure: PropTypes.func,
  onThreeDSFailure: PropTypes.func,
  onRef: PropTypes.func,
  toggleSpinner: PropTypes.func,
};

export default ThreeDSContainer;
