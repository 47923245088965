import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BodyText,
  IconButton,
  QuestionSmIcon,
  Sheet,
  Button,
} from '@paypalcorp/pp-react';
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';

const FeePricing = ({ feeAmounts, getLocalizedCurrency }) => {
  const worldReady = useContext(WorldReadyContext);
  const [openHelperSheet, setOpenHelperSheet] = useState(false);

  const paymentAmount = getLocalizedCurrency(
    feeAmounts?.payment_amount?.value || '0.00',
    feeAmounts?.payment_amount?.currency || 'USD'
  );

  const totalFee = getLocalizedCurrency(
    feeAmounts?.total_fee?.value || '0.00',
    feeAmounts?.total_fee?.currency || 'USD'
  );

  const totalAmountPaid = getLocalizedCurrency(
    feeAmounts?.total_amount_paid?.value || '0.00',
    feeAmounts?.total_amount_paid?.currency || 'USD'
  );

  return (
    <>
      <div className="fee-donation">
        <BodyText>
          <Message id="guest.reviewFees.donation" />
        </BodyText>
        <BodyText>{paymentAmount}</BodyText>
      </div>
      <div className="fee-donation">
        <BodyText className="pricing-fee">
          <Message id="guest.reviewFees.fee" />
          <IconButton
            className="tooltip-icon-button"
            buttonType="tertiary"
            svgIconComponent={QuestionSmIcon}
            size="sm"
            onClick={() => setOpenHelperSheet(true)}
          >
            <Message id="guest.reviewFees.helperTitle" />
          </IconButton>
        </BodyText>
        <BodyText>{totalFee}</BodyText>
      </div>
      <div className="fee-donation">
        <BodyText strong>
          <Message id="guest.reviewFees.total" />
        </BodyText>
        <BodyText strong>{totalAmountPaid}</BodyText>
      </div>

      <Sheet
        className="pricing-helper-sheet"
        id="pricing-helper-sheet"
        title={new MessageFormat(worldReady, {
          id: 'guest.reviewFees.helperTitle',
        }).format()}
        hideTitle={false}
        isOpen={openHelperSheet}
        onClose={() => setOpenHelperSheet(false)}
      >
        <BodyText className="helper-text-description">
          <Message id="guest.reviewFees.helperDescription" />
        </BodyText>
        <div className="button-link">
          <Button
            as="a"
            target="_blank"
            href="/webapps/mpp/paypal-fees"
            role="button"
            secondary
          >
            <Message id="guest.reviewFees.helperButton" />
          </Button>
        </div>
      </Sheet>
    </>
  );
};

FeePricing.propTypes = {
  feeAmounts: PropTypes.object,
  getLocalizedCurrency: PropTypes.func,
};

export default FeePricing;
