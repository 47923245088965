import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import useInterval from './hooks/useInterval';
import { formatAmount, normalizeNumber } from '../utility/formator';
import { BodyText } from '@paypalcorp/pp-react';
import { DonorAvatar } from '@paypalcorp/donate-react';
import { Message } from '@paypalcorp/worldready-react';

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function Flyout({ contributionsList }) {
  const [startShowing, setStartShowing] = useState(false);
  const [showFlyouts, setShowFlyouts] = useState(false);
  const [randomContribution, setRandomContribution] = useState({});

  function getRandomContribution() {
    return contributionsList[getRandomInt(contributionsList.length)];
  }

  // Start showing flyouts after 10s
  useEffect(() => {
    const flyoutTimer = setTimeout(() => {
      setStartShowing(true);
      setShowFlyouts(true);
      setRandomContribution(getRandomContribution());
    }, 10000);
    return () => clearTimeout(flyoutTimer);
  }, []);

  // Get a new contribution and show flyout every 90s
  // Needs useInterval so that the contributionsList gets updated when more are loaded
  useInterval(
    () => {
      setShowFlyouts(true);
      setRandomContribution(getRandomContribution());
    },
    startShowing ? 90000 : null
  );

  // Hide flyout every 10s after setting showFlyouts to true
  useEffect(() => {
    if (showFlyouts) {
      const hideInterval = setTimeout(() => {
        setShowFlyouts(false);
      }, 10000);
      return () => clearTimeout(hideInterval);
    }
  }, [showFlyouts]);

  function getFlyoutText() {
    if (randomContribution.donor_name) {
      return (
        <Message
          id="landing.wr.campaigns.flyout.contribution"
          donorName={randomContribution.donor_name}
          amount={
            <strong>
              {formatAmount({
                value: normalizeNumber(randomContribution.amount),
                currency: randomContribution.currency_code,
              })}
            </strong>
          }
        />
      );
    } else if (randomContribution.shareDonorInfo === false) {
      return (
        <Message
          id="landing.wr.campaigns.flyout.anonymous"
          amount={
            <strong>
              {formatAmount({
                value: normalizeNumber(randomContribution.amount),
                currency: randomContribution.currency_code,
              })}
            </strong>
          }
        />
      );
    }
  }

  if (!startShowing || isEmpty(randomContribution)) {
    return null;
  }

  return (
    <div id="flyout" className={`${showFlyouts ? 'slideIn' : 'slideOut'}`}>
      <div>
        <DonorAvatar
          photo={randomContribution.photos?.[6]?.url}
          color={randomContribution.color}
          name={randomContribution.donor_name}
          isAnonymousAvatar={randomContribution?.shareDonorInfo ? false : true}
          className="avatar"
          size="sm"
        />
      </div>
      <BodyText>{getFlyoutText()}</BodyText>
    </div>
  );
}

Flyout.propTypes = {
  contributionsList: PropTypes.array,
};

export default Flyout;
