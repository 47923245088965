import withPageTrack from '../../confirmation/withPageTrack';
import withPostTransactionRequest from '../../confirmation/withPostTransactionRequest';
import PendingView from '../../confirmation/PendingView';
import SuccessView from '../../confirmation/SuccessView';

const FLOW = 'guest';
const POST_TRANSACTION_URL = '/donate/guest/postTransaction';

export const GuestConfirmationPending = withPageTrack(
  withPostTransactionRequest(PendingView, POST_TRANSACTION_URL),
  FLOW,
  'pending'
);

export const GuestConfirmationSuccess = withPageTrack(
  withPostTransactionRequest(SuccessView, POST_TRANSACTION_URL),
  FLOW,
  'success'
);
