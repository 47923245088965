import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SegmentedController,
  SegmentedControllerPanel,
} from '@paypalcorp/pp-react';
import AppStore from '../../../stores/AppStore';

const RecurringSegmentedController = ({ frequencies, handleFreqChange }) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  useEffect(() => {
    let indx = 0;
    const selectedFrequency = AppStore.getSelectedRecurringFrequency();

    for (let i = 0; i < frequencies.length; i++) {
      if (frequencies[i].value === selectedFrequency?.toUpperCase()) {
        indx = i;
        break;
      }
    }
    setSelectedTabIdx(indx);
  }, [frequencies]);

  const handleTabSelection = (e, tabIdx) => {
    setSelectedTabIdx(tabIdx);
    const freq = frequencies[tabIdx].value;

    handleFreqChange(e, freq);
  };

  return frequencies?.length > 0 ? (
    <SegmentedController
      name="TabsGroup2"
      selectedTabIdx={selectedTabIdx}
      tabs={frequencies.map((option) => ({
        label: option.primaryText,
        id: `${option.primaryText}-id`,
        panelId: `${option.primaryText}-panelid`,
      }))}
      onClick={handleTabSelection}
      className="frequency-options"
    >
      {frequencies.map((option) => (
        <SegmentedControllerPanel
          id={`${option.primaryText}-panelid`}
          tabId={`${option.primaryText}-id`}
          key={`${option.primaryText}-panelid`}
        />
      ))}
      );
    </SegmentedController>
  ) : null;
};

RecurringSegmentedController.propTypes = {
  frequencies: PropTypes.array,
  handleFreqChange: PropTypes.func,
};

export default RecurringSegmentedController;
