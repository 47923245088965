import React, { useEffect, useState } from 'react';
import { CaptionText } from '@paypalcorp/pp-react';
import { StyleSheet, css } from 'aphrodite';
import AppDispatcher from '../dispatcher/AppDispatcher';
import * as AppConstants from '../utility/constants';
import { Message } from '@paypalcorp/worldready-react';

const styles = StyleSheet.create({
  disclaimerWrapper: {
    maxWidth: 350,
    margin: 'auto',
  },
  disclaimer: {
    margin: 'auto',
  },
  disclaimerCaptionText: {
    marginTop: 12,
    color: '#687173',
    textAlign: 'center',
  },
});

export default function NotTaxDeductibleLegalText() {
  const [isPPCC, setIsPPCC] = useState(undefined);
  let dispatcherMessages;
  const registerAppDispatcherMessages = () => {
    dispatcherMessages = AppDispatcher.register((payload) => {
      if (
        payload.action &&
        payload.action.actionType === AppConstants.UPDATE_PPCC_STATUS
      ) {
        setIsPPCC(payload.action.item);
      }
    });
  };

  useEffect(() => {
    registerAppDispatcherMessages();
    return function cleanup() {
      AppDispatcher.unregister(dispatcherMessages);
    };
  }, []);
  if (isPPCC || isPPCC === undefined) return null;
  return (
    <div className={` ${css(styles.disclaimerWrapper)}`}>
      <CaptionText className={` ${css(styles.disclaimerCaptionText)}`}>
        <Message id="buttons.partner.landing.mayNotBeTaxDeductible" />
      </CaptionText>
    </div>
  );
}
