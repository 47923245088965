import React from 'react';
import PropTypes from 'prop-types';
import { CriticalIcon } from '@paypalcorp/pp-react-icons';

export default function NoteFieldError(props) {
  const errorText = props.errorText;

  if (!errorText) {
    return null;
  }
  return (
    <div className="note-field-error">
      <span>
        <CriticalIcon size="xs" className="critical-icon" />
        <span
          role="alert"
          className="error-text"
          data-testid="note-field-error"
        >
          {errorText}
        </span>
      </span>
    </div>
  );
}

NoteFieldError.propTypes = {
  errorText: PropTypes.string,
};
