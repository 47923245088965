import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { PresetAmounts } from '@paypalcorp/donate-react';
import templateData from '../../utility/getTemplateData';
import * as AppActions from '../../actions/AppActions';
import AppStore from '../../stores/AppStore';
import {
  isOptInCoverFee,
  shouldSelectPresetAmount,
} from '../coverFee/feeUtils';
import { formatAmountFromServerDNW, isRightSymbol } from './amountUtils';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { trackSelectedPresetAmount } from '../../fpti/helper';

function PresetAmountRow(props) {
  const worldready = useContext(WorldReadyContext);
  const presetOther = new MessageFormat(worldready, {
    id: 'common.amount.amount.presetOther',
  }).format();
  const placeholderOtherAmount = new MessageFormat(worldready, {
    id: 'common.amount.amount.placeholderOtherAmount',
  }).format();

  const getSelectedOption = () => {
    if (AppStore.getDonationAmountType() === 'preset') {
      return AppStore.getDonationAmount();
    } else if (AppStore.getDonationAmountType() === 'userinput') {
      return 'Other';
    }
    return '';
  };

  const cipFailedOrPending = () => {
    return props.cipStatus === 'failed' || props.cipStatus === 'pending';
  };

  const [presetAmounts, setPresetAmounts] = useState(
      templateData.presetAmounts
    ),
    [selectedOption, setSelectedOption] = useState(getSelectedOption()),
    [showOtherAmount, setShowOtherAmount] = useState(
      AppStore.getDonationAmountType() === 'userinput' ? true : false
    ),
    [amountFieldValue, setAmountFieldValue] = useState(props.charityAmount);

  const handlePresetAmountClick = (value) => {
    setSelectedOption(value);

    const indexValue = presetAmounts.indexOf(value);

    trackSelectedPresetAmount(indexValue >= 0 ? indexValue : 'other');
    if (value !== 'Other') {
      // To be consistent with the hero currency component
      // we need to update donationAmount in store with only
      // normalized number
      props.handleAmountChange(value);
      // Highlight the selected button
      setShowOtherAmount(false);
      setPresetAmounts(templateData.presetAmounts);
      // Clear the errors from amount field
      if (
        props.showValidationError &&
        (!cipFailedOrPending() ||
          (cipFailedOrPending() && Number(AppStore.getDonationAmount()) < 1000))
      ) {
        props.hideValidationError();
      }

      AppActions.updateDonationAmountType('preset');
    } else {
      props.handleAmountChange('');
      AppActions.updateSelectedPresetButtonId('other');
      AppActions.updateDonationAmountType('userinput');
      setShowOtherAmount(true);
    }
  };

  useEffect(() => {
    // In case user opts in for cover fee and goes back to
    // landing page from guest page, we need to restore back
    // user's original entered amount
    const amountBeforeFee = AppStore.getAmountBeforeFee();
    if (amountBeforeFee && isOptInCoverFee()) {
      if (shouldSelectPresetAmount(amountBeforeFee)) {
        setAmountFieldValue('');
        handlePresetAmountClick(amountBeforeFee);
      } else {
        setAmountFieldValue(amountBeforeFee);
      }
    }
  }, []);

  const handleAmountChange = (amount) => {
    if (amount > 0) {
      props.handleAmountChange(amount);
    }
  };

  const getPresetOptions = (values, symbol, charityAmountCode) => {
    const result = values.map((value) => {
      let amountDisplayValue;

      if (isRightSymbol()) {
        amountDisplayValue = symbol
          ? `${formatAmountFromServerDNW(value, {
              noDecimals: true,
            })} ${symbol}`
          : `${formatAmountFromServerDNW(value, {
              noDecimals: true,
            })}`;
      } else {
        amountDisplayValue = symbol
          ? `${symbol} ${formatAmountFromServerDNW(value, {
              noDecimals: true,
            })}`
          : `${formatAmountFromServerDNW(value, {
              noDecimals: true,
            })}`;
      }

      return {
        label: amountDisplayValue,
        secondaryLabel: charityAmountCode,
        value,
      };
    });
    return [...result, { label: presetOther, value: 'Other' }];
  };

  const presetOptions = getPresetOptions(
    presetAmounts,
    props.charityAmountSymbol,
    props.charityAmountCode
  );

  return (
    <>
      <PresetAmounts
        presetOptions={presetOptions}
        selectedOption={selectedOption}
        handlePresetAmountClick={handlePresetAmountClick}
        showOtherAmount={showOtherAmount}
        amountFieldValue={amountFieldValue}
        charityAmountSymbol={props.charityAmountSymbol}
        charityAmountCode={props.charityAmountCode}
        showValidationError={props.showValidationError}
        messages={{
          validationErrorMessage: props.validationErrorMessage,
          placeholderOtherAmount,
        }}
        handleAmountChange={handleAmountChange}
        isPreview={false}
      ></PresetAmounts>
    </>
  );
}

PresetAmountRow.propTypes = {
  hideValidationError: PropTypes.func,
  handleAmountChange: PropTypes.func,
  handleAmountFocus: PropTypes.func,
  charityAmount: PropTypes.string,
  showValidationError: PropTypes.bool,
  charityAmountSymbol: PropTypes.string,
  charityAmountCode: PropTypes.string,
  decimalPoint: PropTypes.string,
  isAmountEditable: PropTypes.bool,
  validationErrorMessage: PropTypes.string,
  validationErrorCode: PropTypes.string,
};
export default PresetAmountRow;
