import { Component } from 'react';
import PropTypes from 'prop-types';
import { getParameterByName } from '../utility/queryStringHelper';
import withDonate from './guest/WithDonate';
import * as AppActions from '../actions/AppActions';
import AppStore from '../stores/AppStore';
import _get from 'lodash/get';
import templateData from '../utility/getTemplateData';
import { isLoggedInUser, isOneTouchUser } from '../utility/authUtils';
import { trackCaptureOrderPageIM } from '../fpti/fpti';
import withWorldReady from './withWorldReady';

class CaptureOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      accessToken: '',
      isSubmitted: false,
      backBtnVisible: false,
      responseData: '',
      wTransactionEntry: '',
      requestError: '',
    };
  }

  componentDidMount() {
    this.props.toggleSpinner(true);
    const donateToken = getParameterByName('dtoken');
    const orderToken = getParameterByName('token');
    const payerID = getParameterByName('PayerID');

    this.captureOrder(orderToken, donateToken, payerID);
  }

  captureOrder = (orderToken, donateToken, payerID) => {
    const { orderStatus } = AppStore.getOrderStatus() || {};
    const { token } = templateData;

    if (orderStatus === 'COMPLETED') {
      window.location.href = `/donate/paymentComplete?token=${token}`;
      return;
    }

    this.props
      .captureOrder(orderToken, donateToken, payerID)
      .then((response) => response.json())
      .then((result) => {
        const confirmationNumber =
          _get(result, 'data.confirmationData.confirmationNumber') ||
          _get(result, 'data.confirmationData.postBackData.txn_id');
        if (!confirmationNumber) {
          this.props.history.push(`/donate/error/payment?token=${token}`);
          return;
        }

        AppActions.updateOrderStatus({ orderStatus: 'COMPLETED' });

        const authState = _get(result, 'authState');
        const isMemberFlow =
          isLoggedInUser(authState) || isOneTouchUser(authState);
        const confirmationData = _get(result, 'data.confirmationData', {});
        this.handlePaymentSuccess(confirmationData, isMemberFlow);
      })
      .catch(() => {
        this.props.history.push(`/donate/error/payment?token=${token}`);
      });
  };

  handlePaymentSuccess = (response, isMemberFlow) => {
    AppActions.updateConfirmation({ confirmation: response });
    this.handleConfirmation(response, isMemberFlow);
  };

  handleConfirmation = (response, isMemberFlow) => {
    const { token } = templateData;
    const flow = isMemberFlow ? 'member' : 'guest';
    const page = response.isOfacPending ? 'pending' : 'confirmation';

    const url = token
      ? `/donate/${flow}/${page}/${window.location.search}`
      : `/donate/${flow}/${page}`;

    trackCaptureOrderPageIM({
      flow,
      confirmationData: response,
    });

    this.props.history.push(url);
  };

  render() {
    return null;
  }
}

CaptureOrder.propTypes = {
  toggleSpinner: PropTypes.func,
  captureOrder: PropTypes.func,
  history: PropTypes.object,
};

export default withWorldReady(withDonate(CaptureOrder));
