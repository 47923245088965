import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import templateData from '../utility/getTemplateData';
import * as AppActions from '../actions/AppActions';
import AppStore from '../stores/AppStore';
import { addClass, removeClass } from '../utility/domUtils';
import {
  isExternalFlow,
  isCampaigns,
  isGNC,
  isPPCampaign,
} from '../utility/productIntegrationUtils';
import isEmpty from 'lodash/isEmpty';
import { EVENT_NAME } from '../utility/constants';
import { IconButton, TextArea, BodyText, AddIcon } from '@paypalcorp/pp-react';
import NoteFieldError from './NoteFieldError';
import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';

const MAX_CHAR_LIMIT = 250;

function NoteComponent(props) {
  const worldReady = useContext(WorldReadyContext);

  const label = new MessageFormat(worldReady, {
    id: 'common.note.note.label',
  }).format();
  const lengthLimit = new MessageFormat(worldReady, {
    id: 'common.note.note.error.lengthLimit',
  }).format();
  const campaignsLabel = new MessageFormat(worldReady, {
    id: 'common.note.note.campaigns.label',
  }).format();

  let providedLabel = isCampaigns() ? campaignsLabel : label;

  if (templateData.charityNoteEnabled && templateData.charityNoteLabel) {
    providedLabel = templateData.charityNoteLabel;
  }

  if (props.label) {
    providedLabel = props.label;
  }

  let commentTextboxLabel = providedLabel;
  if (isGNC() || isPPCampaign()) {
    commentTextboxLabel = !isEmpty(props.texboxLabel)
      ? props.texboxLabel
      : commentTextboxLabel;
  }

  const [donationNote, setDonationNote] = useState(
    AppStore.getDonationNote() || templateData.donationNote || ''
  );
  const initialNoteLabel = isEmpty(donationNote) ? providedLabel : donationNote;

  const [showLabel, setShowLabel] = useState(true);
  const [showError, setShowError] = useState(false);
  const [noteLabel, setNoteLabel] = useState(initialNoteLabel);
  const textAreaEl = useRef(null);
  const showOnlyCommentTextbox = templateData?.showOnlyCommentTextbox ?? false;

  const isNoteTextPresent = donationNote.length > 0 ? true : false;

  useEffect(() => {
    if (!showLabel && !isEmpty(textAreaEl) && !showOnlyCommentTextbox) {
      textAreaEl.current.focus();
    }
  }, [showLabel]);
  useEffect(() => {
    if (isNoteTextPresent || showOnlyCommentTextbox) {
      setShowLabel(false);
    }
  }, []);
  useEffect(() => {
    if (props.isNotePreview) {
      setNoteLabel(initialNoteLabel);
    }
  }, [initialNoteLabel]);

  const directionality = templateData.contextLocalityDir;

  const handleClick = () => {
    trackLinkClick(LINK_NAME.NOTE_PAD, {
      eventName: isExternalFlow()
        ? EVENT_NAME.DW_GIVING_DONATE_SETUP_COMMENT_PRESSED
        : '',
    });
    setShowLabel(false);
  };

  const handleBlur = (event) => {
    const note = event.target.value;

    // Remove all whitespaces
    if (!isEmpty(note) && !isEmpty(note.trim())) {
      AppActions.changeNote({ note });
      setNoteLabel(note);
    } else {
      AppActions.changeNote({ note: '' });
      setNoteLabel(providedLabel);
      if (!showOnlyCommentTextbox) {
        setShowLabel(true);
      }
    }
  };

  const showNoteFieldError = () => {
    setShowError(true);
  };

  const hideNoteFieldError = () => {
    setShowError(false);
  };

  const handleOnChange = (event) => {
    const value = event.target.value;
    setDonationNote(value);

    if (value && value.length >= MAX_CHAR_LIMIT) {
      addClass('.note_wrap', ['animated', 'shake']);
      setTimeout(() => {
        removeClass('.note_wrap', ['animated', 'shake']);
      }, 1000);
      showNoteFieldError();
    } else {
      hideNoteFieldError();
    }
  };

  return (
    <div className="client_note_row">
      <div
        className={`${
          isExternalFlow() ? 'client_note_wrapper' : 'donate_note_wrapper'
        }`}
      >
        {showLabel ? (
          <label htmlFor="note" onClick={handleClick}>
            <IconButton
              svgIconComponent={AddIcon}
              size="xs"
              style={{ color: 'inherit', marginLeft: '-15px' }}
              disabled={props.isNotePreview || false}
              buttonType="tertiary"
            >
              {noteLabel}
            </IconButton>
            <BodyText className="body-text-label" aria-hidden="true">
              {noteLabel}
            </BodyText>
          </label>
        ) : (
          <div className="note_wrap">
            <TextArea
              id="note"
              name="note"
              maxRows={3}
              minRows={1}
              value={donationNote}
              showCharacterCounter
              maxLength={MAX_CHAR_LIMIT}
              maxCharacters={MAX_CHAR_LIMIT}
              onChange={handleOnChange}
              onBlur={handleBlur}
              ref={textAreaEl}
              errorText={showError ? lengthLimit : null}
              label={commentTextboxLabel}
              dir={directionality}
            />
          </div>
        )}

        {showError && showLabel && <NoteFieldError errorText={lengthLimit} />}
      </div>
    </div>
  );
}

NoteComponent.propTypes = {
  label: PropTypes.string,
  isNotePreview: PropTypes.bool,
  texboxLabel: PropTypes.string,
};

export default NoteComponent;
