import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_CONVERSION_OPTIONS } from '../../pages/guest/GuestReview';
import { Card } from '@paypalcorp/pp-react-card';
import { Sheet } from '@paypalcorp/pp-react-modal';
import { IconButton } from '@paypalcorp/pp-react';
import { BodyText, CaptionText, HeadingText } from '@paypalcorp/pp-react-text';
import { PaypalAppIcon, CreditCardIcon } from '@paypalcorp/pp-react-icons';
import { CheckmarkBackgroundIcon } from '@paypalcorp/pp-react-icons';
import { Button } from '@paypalcorp/pp-react-buttons';
import { checkKeyPressed } from '../../utility/accessibilityUtils';
import {
  Context as WorldReadyContext,
  Message,
} from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';
const CurrencyConversionOption = (props) => {
  const getOptionIcon = (type) => {
    if (type === 'paypal-app') {
      return (
        <IconButton
          buttonType="secondary"
          size="xl"
          name={type}
          svgIconComponent={PaypalAppIcon}
        />
      );
    } else if (type === 'credit-cards') {
      return (
        <IconButton
          buttonType="secondary"
          size="xl"
          name={type}
          svgIconComponent={CreditCardIcon}
        />
      );
    }
  };

  return (
    <Card
      className={`${props.className} ${
        props.isSelected ? 'show-highlight' : 'border-default'
      }`}
      role="checkbox"
      aria-checked={props.isSelected}
      tabIndex="0"
      onClick={props.handleOptionSelection}
      onKeyDown={(e) =>
        checkKeyPressed(e, () => {
          props.handleOptionSelection();
        })
      }
    >
      <Card.Content>
        <div className="conversion-tile">
          {getOptionIcon(props.type)}
          <div className="content">
            <BodyText className="title" strong>
              {props.title}
            </BodyText>
            {props.rate && <CaptionText strong>{props.rate}</CaptionText>}
            <CaptionText as="span">{props.description}</CaptionText>
          </div>
          <CheckmarkBackgroundIcon
            className={`icon-check ${!props.isSelected && 'hide-icon'}`}
            size="sm"
          />
        </div>
      </Card.Content>
    </Card>
  );
};

CurrencyConversionOption.propTypes = {
  isSelected: PropTypes.bool,
  title: PropTypes.string,
  rate: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  handleOptionSelection: PropTypes.func,
  type: PropTypes.string,
};

const CurrencyConversionModal = (props) => {
  const worldready = useContext(WorldReadyContext);
  const { selectedOption, exchangeRate } = props;
  const withPaypaltitle = new MessageFormat(worldready, {
    id: 'guest.crossBorder.label.withPaypal.title',
  }).format();
  const withPaypalRate = new MessageFormat(worldready, {
    id: 'guest.crossBorder.label.withPayPal.rate',
  }).format({
    amount: exchangeRate,
  });
  const withPaypalmessage = new MessageFormat(worldready, {
    id: 'guest.crossBorder.label.withPayPal.message',
  }).format();
  const withCardtitle = new MessageFormat(worldready, {
    id: 'guest.crossBorder.label.withCard.title',
  }).format();
  const withCardmessage = new MessageFormat(worldready, {
    id: 'guest.crossBorder.label.withCard.message',
  }).format();
  const [isPayPalSelected, setPaypalSelected] = useState(
    selectedOption === CURRENCY_CONVERSION_OPTIONS.PAYPAL
  );

  const handleClose = () => {
    props.hideModal();
  };

  const handleNextButton = () => {
    props.handleOptionSelection(
      isPayPalSelected
        ? CURRENCY_CONVERSION_OPTIONS.PAYPAL
        : CURRENCY_CONVERSION_OPTIONS.ISSUER
    );
  };

  return (
    <Sheet
      className="currency-conversion-modal"
      title=""
      hideTitle={true}
      isOpen={props.isOpen}
      onClose={handleClose}
    >
      <HeadingText size="lg" className="text-center title">
        <Message id="guest.crossBorder.label.modalTitle" />
      </HeadingText>
      <BodyText className="text-center subTitle">
        <Message id="guest.crossBorder.label.modalSubTitle" />
      </BodyText>

      <CurrencyConversionOption
        isSelected={isPayPalSelected}
        title={withPaypaltitle}
        rate={withPaypalRate}
        description={withPaypalmessage}
        handleOptionSelection={() => setPaypalSelected(true)}
        className="option-paypal"
        type="paypal-app"
      />
      <CurrencyConversionOption
        isSelected={!isPayPalSelected}
        title={withCardtitle}
        description={withCardmessage}
        handleOptionSelection={() => setPaypalSelected(false)}
        className="option-card"
        type="credit-cards"
      />
      <div className="text-center">
        <Button className="btn-sheet-wide" onClick={handleNextButton}>
          <Message id="guest.crossBorder.label.modalButton" />
        </Button>
      </div>
    </Sheet>
  );
};

CurrencyConversionModal.propTypes = {
  handleOptionSelection: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  selectedOption: PropTypes.string,
  exchangeRate: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default CurrencyConversionModal;
