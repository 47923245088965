/**
 * Card Utilities
 * For use with linking a card
 *
 * - get card type [visa, mastercard, amex, discover, maestro, unionpay] based on the card number
 */
import { MessageFormat } from '@paypalcorp/worldready';
import { getWorldReady } from './worldReadyUtils';

export const defaultCardSpec = {
  type: 'card',
  fields: {
    ccNumber: {
      required: true,
      pattern: '4[0-9]{12}(?:[0-9]{3})?',
      maxlength: 19,
    },
    expirationDate: {
      required: true,
      pattern: '(0[1-9]|1[012])[/]((20)[0-9]{2}|[0-9]{2})',
      maxlength: 7,
    },
    csc: {
      required: true,
      pattern: '[0-9]*',
      maxlength: 3,
      minlength: 3,
    },
  },
};

/**
 * Get the card type based on the card number
 * @param {Object} cardSpecs - the card specifications based on user's locale
 * @param {String} cardNumber - the valid card number (numbers only)
 * @return {Object} cardType - the card type specs for a specific card
 */
export function getSpecificCardSpec({ cardTypes }, cardNumber) {
  cardNumber = cardNumber.replace(/\D+/g, '');

  for (const card in cardTypes) {
    const cardSpec = cardTypes[card];
    if (
      new RegExp(`^${cardSpec.fields.ccNumber.autodetect}$`).test(cardNumber)
    ) {
      return cardSpec;
    }
  }
  return defaultCardSpec;
}

const PAYMENT_CARDS = {
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  MASTER_CARD: 'MASTER_CARD',
  MAESTRO: 'MAESTRO',
  AMEX: 'AMEX',
  DISCOVER: 'DISCOVER',
  JCB: 'JCB',
  CHINA_UNION_PAY: 'CHINA_UNION_PAY',
};

export function getPaymentCardNetwork(source) {
  const worldReady = getWorldReady();
  switch (source.network) {
    case PAYMENT_CARDS.VISA:
      return new MessageFormat(worldReady, {
        id: 'common.fundingTypes.fundingType.visa',
      }).format();
    case PAYMENT_CARDS.MASTERCARD:
    case PAYMENT_CARDS.MASTER_CARD:
      return new MessageFormat(worldReady, {
        id: 'common.fundingTypes.fundingType.mastercard',
      }).format();
    case PAYMENT_CARDS.MAESTRO:
      return new MessageFormat(worldReady, {
        id: 'common.fundingTypes.fundingType.maestro',
      }).format();
    case PAYMENT_CARDS.AMEX:
      return new MessageFormat(worldReady, {
        id: 'common.fundingTypes.fundingType.amex',
      }).format();
    case PAYMENT_CARDS.DISCOVER:
      return new MessageFormat(worldReady, {
        id: 'common.fundingTypes.fundingType.discover',
      }).format();
    case PAYMENT_CARDS.CHINA_UNION_PAY:
      return new MessageFormat(worldReady, {
        id: 'common.fundingTypes.fundingType.china_union_pay',
      }).format();
    default:
      return source.network;
  }
}
