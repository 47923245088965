import React, { useEffect } from 'react';
import { HeadingText, BodyText, Link, Button } from '@paypalcorp/pp-react';
import CloseButton from '../../components/CloseButton';
import {
  isInContext,
  isMobileOrInContext,
} from '../../utility/inContextDonation';
import withTheme from '../../components/withTheme';
import { isExternalFlow } from '../../utility/productIntegrationUtils';
import { addClass } from '../../utility/domUtils';

function FullPageError({
  title,
  body,
  body2,
  linkLabel,
  handleLinkClick,
  buttonLabel,
  handleButtonClick,
  showCloseButton = false,
  centerBody = false,
}) {
  useEffect(() => {
    if (isInContext()) {
      addClass('#mainWrapper header.donate_global_nav', ['hide-header']);
    }
  }, []);
  return (
    <div
      className={`donate_wrapper ${
        isMobileOrInContext() ? 'mobile_view' : ''
      } ${isInContext() ? 'gn_donate_wrapper' : ''} fullPageError`}
    >
      {isExternalFlow() && showCloseButton && <CloseButton />}
      <HeadingText size="sm" className="title">
        {title}
      </HeadingText>
      {body ? (
        <BodyText className={`body ${centerBody ? 'text-center' : ''}`}>
          {body}
        </BodyText>
      ) : null}
      {body2 ? (
        <BodyText className={`body ${centerBody ? 'text-center' : ''}`}>
          {body2}
        </BodyText>
      ) : null}

      {buttonLabel ? (
        <Button secondary onClick={handleButtonClick} className="cta">
          {buttonLabel}
        </Button>
      ) : null}

      {linkLabel ? (
        <Link className="cta" onClick={handleLinkClick}>
          {linkLabel}
        </Link>
      ) : null}
    </div>
  );
}

export default withTheme(FullPageError);
