import './publicPath';

import 'consumerweb-shim';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../utility/bootstrap';
import React, { Component, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Router } from 'react-router-dom';
import history from './configureHistory';
import CookiedUserPage from './../pages/landing/landing';
import CampaignLanding from '../pages/landing/campaignLanding';
import Review from './../pages/guest/GuestReview';
import {
  GuestConfirmationPending,
  GuestConfirmationSuccess,
} from '../pages/guest/confirmation';
import PaymentError from './../pages/error/paymentError';
import UnilateralError from '../pages/error/UnilateralError';
import templateData from './../utility/getTemplateData';
import { isOneTouchUser, isAuthenticatedUser } from 'utility/authUtils';
import { parseUrlQuery } from '../utility/urlUtils';
import { setShouldRTL } from '../utility/paypalAphrodite';
import { showSpinnerOverlay, hideSpinnerOverlay } from '../utility/helper';
import ThreeDSError from '../pages/error/threeDSError';
import { reactLazyPreload } from '../utility/lazyLoader';
import CaptureOrder from '../components/CaptureOrder';
import CancelAndRedirectOrder from '../components/CancelAndRedirectOrder';
import {
  MemberConfirmationPending,
  MemberConfirmationSuccess,
} from '../pages/member/confirmation';
import Footer from '../components/footer/footer';

import '../../css/ppvx-app.less';
import '../../css/portable-onboarding.less';
import { addClass, removeClass } from '../utility/domUtils';
import {
  isGNC,
  isPPCampaign,
  isCampaigns,
} from '../utility/productIntegrationUtils';
import withWorldReady from '../components/withWorldReady';

// set the direction for styles (whether to convert them to RTL)
const { locality: { directionality = 'ltr' } = {} } = templateData;
setShouldRTL(directionality !== 'rtl');

const { currentUserState, isUnilateralAccount, paymentStatus } = templateData;

const GuestPage = reactLazyPreload(() =>
  import(/* webpackChunkName: "GuestPage" */ './../pages/guest/guest')
);

function FallbackSpinner() {
  useEffect(() => {
    showSpinnerOverlay();
  }, []);

  return <></>;
}

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCookied: currentUserState === 'cookied',
      isOneTouch: isOneTouchUser(),
    };
  }

  componentDidMount() {
    if (!(isGNC() || isPPCampaign())) {
      addClass('#mainWrapper', ['landing-page']);
    }

    if (isUnilateralAccount) {
      history.push('/donate/error/noaccount');
      return;
    }
    // Only hiding spinner for cookied user and if payment token status is not complete
    if (this.state.userCookied && paymentStatus !== 'complete') {
      // In case of onboarding, guest.js will hide the spinner
      if (!templateData.onboardingEnabled) {
        hideSpinnerOverlay();
      }
    }

    if (!isAuthenticatedUser()) {
      GuestPage.preload();
    }
  }
  componentWillUnmount() {
    if (!(isGNC() || isPPCampaign())) {
      removeClass('#mainWrapper', ['landing-page']);
    }
  }

  getPageToRender() {
    // If `signup` param is present, render GuestPage
    const isSignupRoute = !!parseUrlQuery().signup;
    if (isSignupRoute) {
      return <GuestPage />;
    }
    return <CookiedUserPage />;
  }

  render() {
    return <div>{this.getPageToRender()}</div>;
  }
}

function CampaignLandingPage() {
  const getPageToRender = () => {
    // If `signup` param is present, render GuestPage
    const isSignupRoute = !!parseUrlQuery().signup;
    if (isSignupRoute) {
      return <GuestPage />;
    }
    return <CampaignLanding />;
  };

  return <>{getPageToRender()}</>;
}

const MainRouter = () => (
  <Router history={history}>
    <Suspense fallback={<FallbackSpinner />}>
      <Switch>
        <Route
          exact
          path="/donate"
          component={isCampaigns() ? CampaignLandingPage : LandingPage}
        />
        <Route exact path="/donate/capture" component={CaptureOrder} />
        <Route
          exact
          path="/donate/orders/cancel"
          component={CancelAndRedirectOrder}
        />
        <Route exact path="/donate/guest" component={GuestPage} />
        <Route exact path="/donate/guest/crossborder" component={Review} />
        <Route
          exact
          path="/donate/guest/confirmation"
          component={GuestConfirmationSuccess}
        />
        <Route
          exact
          path="/donate/guest/pending"
          component={GuestConfirmationPending}
        />
        <Route
          exact
          path="/donate/member/confirmation"
          component={MemberConfirmationSuccess}
        />
        <Route
          exact
          path="/donate/member/pending"
          component={MemberConfirmationPending}
        />
        <Route exact path="/donate/error/payment" component={PaymentError} />
        <Route
          exact
          path="/donate/error/noaccount"
          component={UnilateralError}
        />
        <Route exact path="/donate/error/threeDS" component={ThreeDSError} />
      </Switch>
    </Suspense>
    <Footer />
  </Router>
);

const RouterWithWorldReady = withWorldReady(MainRouter);

ReactDOM.render(
  <RouterWithWorldReady />,
  document.getElementById('donateWrapper')
);
