import React, { useState } from 'react';
import AppStore from '../../../stores/AppStore';
import PropTypes from 'prop-types';
import { BodyText, CaptionText, Button } from '@paypalcorp/pp-react';
import {
  showSpinnerOverlay,
  hideSpinnerOverlay,
} from '../../../utility/helper';
import { trackLinkClick } from '../../../fpti/fpti';
import { LINK_NAME, PAGE_SPEC } from '../../../fpti/fptiConstants';
import CurrencyConversionModal from '../../../components/currencyConversion/CurrencyConversionModal';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import { Message } from '@paypalcorp/worldready-react';

const CrossBorder = ({
  isPaypalCurrencyConversion,
  funds,
  getFundingOptions,
  currencyConversionOption,
  getLocalizedCurrency,
  stateCurrency,
  hasFees,
}) => {
  const fundingOptions = AppStore.getFundingOptions();
  const DYNAMIC_CURRENCY_CONVERSION_TAG = 'DYNAMIC_CURRENCY_CONVERSION';

  const rate = `${getLocalizedCurrency(
    '1.00',
    stateCurrency.inCurrency
  )}= ${getLocalizedCurrency(
    stateCurrency.exchangeRate,
    stateCurrency.outCurrency,
    4
  )}`;

  const [currencyConversionOpen, setCurrencyConversionOpen] = useState(false);

  const handleOptionSelection = (option) => {
    if (currencyConversionOption !== option) {
      setCurrencyConversionOpen(false);
      showSpinnerOverlay();
      getFundingOptions(option).finally(() => {
        hideSpinnerOverlay();
      });
    } else {
      setTimeout(() => {
        setCurrencyConversionOpen(false);
      }, 300);
    }
  };

  /**
   * Returns true if currency conversion options should be displayed
   * @param  {Array} fundingSources Funding Sources
   * @return {Boolean}              True if currency conversion options should be displayed
   */
  const shouldShowCurrencyConversionOptions = () => {
    const fundingSources = fundingOptions.sources || [];
    const lastFundingSource = fundingSources[fundingSources.length - 1];
    const tags = _get(lastFundingSource, 'tags');
    return _includes(tags, DYNAMIC_CURRENCY_CONVERSION_TAG);
  };

  return (
    <>
      {!hasFees && (
        <div className="total-donation">
          <BodyText strong>
            <Message id="guest.crossBorder.label.youllDonate" />
          </BodyText>
          <BodyText strong>{funds}</BodyText>
        </div>
      )}

      <div className="change-conversion">
        <BodyText>
          {isPaypalCurrencyConversion ? (
            <Message id="guest.crossBorder.label.withPaypal.title" />
          ) : (
            <Message id="guest.crossBorder.label.withCard.title" />
          )}
        </BodyText>
        <Button
          tertiary
          onClick={() => {
            trackLinkClick(LINK_NAME.CURRENCY_CONVERSION, {
              pageSpec: PAGE_SPEC.CROSS_BORDER_PAGE,
            });
            setCurrencyConversionOpen(true);
          }}
        >
          <Message id="guest.crossBorder.label.changeCurrencyButton" />
        </Button>
      </div>
      {isPaypalCurrencyConversion && (
        <CaptionText>
          <Message id="guest.crossBorder.label.conversion" amount={rate} />
        </CaptionText>
      )}

      {shouldShowCurrencyConversionOptions() && (
        <div className="conversion-message">
          {isPaypalCurrencyConversion ? (
            <CaptionText>
              {AppStore.getUserCountry() === 'US' ? (
                <Message id="guest.crossBorder.label.spread" />
              ) : (
                <Message id="guest.crossBorder.label.fee" />
              )}
            </CaptionText>
          ) : null}
        </div>
      )}

      <CurrencyConversionModal
        isOpen={currencyConversionOpen}
        exchangeRate={rate}
        handleOptionSelection={handleOptionSelection}
        hideModal={() => setCurrencyConversionOpen(false)}
        selectedOption={currencyConversionOption}
      />
    </>
  );
};

CrossBorder.propTypes = {
  isPaypalCurrencyConversion: PropTypes.bool,
  funds: PropTypes.string,
  handleCurrencyConversionClick: PropTypes.func,
  exchangeRate: PropTypes.string,
  hasFees: PropTypes.bool,
};

export default CrossBorder;
