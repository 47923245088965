import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import AppStore from '../stores/AppStore';
import * as AppActions from '../actions/AppActions';
import TemplateData from '../utility/getTemplateData';
import { fetcher } from '../utility/domUtils';
import { SingleAmount } from '@paypalcorp/donate-react';
import { BodyText } from '@paypalcorp/pp-react';
import PresetAmountRow from './amount/PresetAmountRow';
import {
  setFeeAmountInStore,
  optOutCoverFee,
  isAtLandingPage,
  isOptInCoverFee,
} from './coverFee/feeUtils';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { getParameterByName } from '../utility/queryStringHelper';
import { __getInitialData } from '../utility/formator';
import { trackErrorIM } from '../fpti/fpti';
import { addFeature, setAmountTypeFpti } from '../fpti/helper';
import { FEATURES } from '../fpti/fptiConstants';
import { getWorldReady } from '../utility/worldReadyUtils';
import { MessageFormat } from '@paypalcorp/worldready';

class AmountWrapper extends React.Component {
  constructor(props) {
    super(props);
    const currencyObj = JSON.parse(
      document.getElementById('currencyLists').innerHTML || {}
    );
    const { currencies: currencyLists, symbols: currencySymbols } =
      currencyObj || {};
    const html = document.getElementsByTagName('html')[0];
    this.directionality = html.getAttribute('dir');

    const userCountry = TemplateData.donorIpCountry;
    const availableCurrencies = currencyLists[userCountry] || currencyLists.US;
    const userAmountCode = availableCurrencies[0];
    const charityAmountCode = AppStore.getDonationCode();
    const isEditable = !!TemplateData.donationAmountEditable;

    this.state = {
      charityAmountCode,
      charityAmount: AppStore.getDonationAmount() || '',
      charityAmountSymbol: AppStore.getDonationSymbol(),
      charityDisabled: false,

      userCountry,
      userAmountCode,
      userAmount: '',
      userDisabled: false,

      availableCurrencies,
      currencySymbols,
      decimalPoint: TemplateData.donationDecimalPoint || '.',
      conversionRate: '',
      isAmountEditable: isEditable,

      showValidationError: props.showValidationError || false,
      validationErrorMessage: props.validationErrorMessage || '',
      validationErrorCode: props.validationErrorCode || '',
      cipStatus: props.cipStatus || null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.showValidationError !== nextProps.showValidationError ||
      this.state.validationErrorMessage !== nextProps.validationErrorMessage
    ) {
      this.setState({
        showValidationError: nextProps.showValidationError,
        validationErrorMessage: nextProps.validationErrorMessage,
        validationErrorCode: nextProps.validationErrorCode,
      });
    }
  }

  componentDidMount = () => {
    // Watch for amount change for the case that donor opts in to cover fee
    // and clicks back button to go back to landing page
    // as we need to restore donor's original entered amount
    this.dispatcherCharityAmount = AppDispatcher.register((payload) => {
      if (payload.action && payload.action.actionType === 'CHANGE_AMOUNT') {
        const { action: { item: { amount = 0 } = {} } = {} } = payload;
        this.setState({
          charityAmount: amount,
        });
      }
    });

    if (this.state.isAmountEditable) {
      addFeature(FEATURES.DONATION_AMOUNT_EDITABLE);
    }
  };

  componentWillUnmount = () => {
    AppDispatcher.unregister(this.dispatcherCharityAmount);
  };

  isGuestReviewPage = () => {
    return (
      window.location.pathname.includes('guest') ||
      ![undefined, null].includes(getParameterByName('signup'))
    );
  };

  normalizeAmount = (amount) => {
    return isNaN(amount) ? 0 : amount;
  };

  setAppAmount = (amount) => {
    AppActions.changeAmount({
      amount,
    });
  };

  getConversionRate = (state, exchangeRate) => {
    const rate =
      exchangeRate.base_currency_code === state.userAmountCode
        ? exchangeRate.quote_amount
        : 1 / exchangeRate.quote_amount;
    return rate.toFixed(2);
  };

  getConversionRequest = (state, params, callback) => {
    this.getConversionRequest = _debounce((st, par, fn) => {
      const queryParams = {
        fromCountry: st.userCountry,
        fromCurrency: st.userAmountCode,
        toCurrency: st.charityAmountCode,
        ...par,
      };

      if (queryParams.fromAmount) {
        queryParams.fromAmount = this.normalizeAmount(queryParams.fromAmount);
      } else {
        queryParams.toAmount = this.normalizeAmount(queryParams.toAmount);
      }
      const url = '/donate/calculate-foreign-exchange';
      this.props.conversionStatus(true);
      Promise.resolve(
        fetcher(url, {
          method: 'POST',
          body: JSON.stringify(queryParams),
        })
      )
        .then((response) => response.json())
        .then(({ result }) => {
          this.props.conversionStatus(false);
          fn(result);
        })
        .catch(() => {
          this.props.conversionStatus(false);
          let amount = queryParams.fromAmount || queryParams.toAmount || '';
          amount += '';
          fn({
            target: { value: amount },
            source: { value: amount },
            exchange_rate: {
              base_currency_code: queryParams.userAmountCode,
              quote_amount: 1,
            },
          });
        });
    }, 500);

    this.getConversionRequest(state, params, callback);
  };

  /*
   * getConversion
   */
  getConversion = (state, params, fn) => {
    const amount = params.fromAmount || params.toAmount || '';
    fn({
      target: { value: amount },
      source: { value: amount },
      exchange_rate: {
        base_currency_code: state.userAmountCode,
        quote_amount: 1,
      },
    });
  };

  handleAmountChange = (amount) => {
    this.setState({ charityAmount: amount });
    this.setAppAmount(amount);

    // let recurringStatus = AppStore.getDonationRecurring() === 'checked';
    // if (recurringStatus) {
    //   if (isAmountGreaterThanZero(amount)) {
    //     $('#donateNowAmt').html(this.state.charityAmountSymbol + amount);
    //   } else {
    //     $('#donateNowAmt').html(' ');
    //   }
    // }

    if (isAtLandingPage()) {
      // Compute fee amount and update store
      setFeeAmountInStore();
      // Let landing page know that fee amount displayed needs to be updated
      this.props.updateFeeAmount();
    } else {
      // Since user updates the amount in the guest page
      // we should opt user out for cover the fee in case
      // they go back to landing page
      if (isOptInCoverFee()) {
        optOutCoverFee();
      }

      // In case user selects a preset button but update the amount
      // in review page, the donation amount type should now be
      // "userinput"
      if (AppStore.getDonationAmountType() === 'preset') {
        setAmountTypeFpti('other');
        AppActions.updateDonationAmountType('userinput');
      }
    }
    const showCipAmountError = this.showCipAmountError();
    if (this.props.updateCipAmountValidation) {
      if (showCipAmountError && Number(amount) > 999) {
        this.props.updateCipAmountValidation(true);
      } else {
        this.props.updateCipAmountValidation(false);
      }
    }
  };

  handleAmountFocus() {
    this.props.onFocus();
  }

  handleTrackError = (errorCode, errorString, errorSourceField) => {
    trackErrorIM({
      errorCode: errorCode || 'DONATION_AMOUNT_VALIDATION_ERROR',
      errorMessage: errorString,
      fieldName: errorSourceField,
    });
  };

  shouldShowPresetAmounts = () => {
    // Should only show preset amount options in landing
    // page not review page to make the experience consistent
    // with member flow. It works better for cover the fees as well

    const presetAmountsCondition =
      TemplateData.enablePresetAmounts && !this.isGuestReviewPage();

    if (presetAmountsCondition) {
      addFeature(FEATURES.PRESET);
    }

    return presetAmountsCondition;
  };

  renderPresetAmounts = () => {
    return (
      <PresetAmountRow
        charityAmount={this.state.charityAmount}
        charityAmountSymbol={this.state.charityAmountSymbol}
        charityAmountCode={this.state.charityAmountCode}
        decimalPoint={this.state.decimalPoint}
        isAmountEditable={this.state.isAmountEditable}
        handleAmountChange={this.handleAmountChange.bind(this)}
        handleAmountFocus={this.handleAmountFocus.bind(this)}
        hideValidationError={this.props.hideValidationError}
        showValidationError={this.state.showValidationError}
        validateDonationAmount={this.props.validateDonationAmount}
        validationErrorMessage={this.state.validationErrorMessage}
        validationErrorCode={this.state.validationErrorCode}
      />
    );
  };

  getRecurringLabel = () => {
    const worldReady = getWorldReady();
    switch (this.props.selectedRecurringFrequency) {
      case 'Week':
        return new MessageFormat(worldReady, {
          id: 'guest.guestForm.label.perWeek',
        }).format();
      case 'Month':
        return new MessageFormat(worldReady, {
          id: 'guest.guestForm.label.perMonth',
        }).format();
      case 'Year':
        return new MessageFormat(worldReady, {
          id: 'guest.guestForm.label.perYear',
        }).format();
      default:
        return null;
    }
  };
  showCipAmountError = () => {
    return this.state.cipStatus === 'pending';
  };

  render() {
    if (this.shouldShowPresetAmounts()) {
      return (
        <div className="preset-amount-wrapper">
          {this.renderPresetAmounts()}
        </div>
      );
    }

    const caamlErrorStyle =
      this.showCipAmountError() && Number(this.state.charityAmount) > 999;
    return (
      <div className="amount-wrapper">
        <>
          <div
            className={`amount ${
              !this.state.showValidationError ? 'no-error' : ''
            }`}
          >
            <SingleAmount
              amount={this.state.charityAmount}
              currencyCode={this.state.charityAmountCode}
              currencySymbol={this.state.charityAmountSymbol}
              handleAmount={this.handleAmountChange.bind(this)}
              showValidationError={this.state.showValidationError}
              validationErrorMessage={this.state.validationErrorMessage}
              isEditable={this.state.isAmountEditable}
              handleAmountFocus={this.handleAmountFocus.bind(this)}
              getAmountBeforeFee={AppStore.getAmountBeforeFee}
              isOptInCoverFee={isOptInCoverFee}
              isAtLandingPage={isAtLandingPage}
              clientMetadata={__getInitialData().clientMetadata}
              handleTrackError={this.handleTrackError}
              validationErrorCode={this.state.validationErrorCode}
              directionality={this.directionality}
              caamlErrorStyle={caamlErrorStyle}
            />
            {AppStore.getDonationRecurring() === 'checked' && (
              <BodyText strong>{this.getRecurringLabel()}</BodyText>
            )}
          </div>
        </>
      </div>
    );
  }
}

AmountWrapper.propTypes = {
  conversionStatus: PropTypes.func,
  showValidationError: PropTypes.bool,
  hideValidationError: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  validationErrorCode: PropTypes.string,
  validateDonationAmount: PropTypes.func,
  onFocus: PropTypes.func,
  updateFeeAmount: PropTypes.func,
  selectedRecurringFrequency: PropTypes.string,
  cipStatus: PropTypes.string,
  updateCipAmountValidation: PropTypes.func,
};

export default AmountWrapper;
