import React, { Component } from 'react';
import { Button, Sheet, HeadingText, BodyText } from '@paypalcorp/pp-react';
import TemplateData from '../../utility/getTemplateData';
import PropTypes from 'prop-types';
import { appendCdnHostName } from '../../utility/geoUtils';
import { sanitizeOrgName } from '../../utility/domUtils';
import BackButton from '../../components/BackButton';
import { isInContext } from '../../utility/inContextDonation';
import { Row, Col } from '@paypalcorp/pp-react';
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';

const orgname = TemplateData.donationName ? TemplateData.donationName : '';
export class RecurringOverlay extends Component {
  handleClose = () => {
    this.props.closeRecurringOverlay();
  };

  noThanks_closeModal = (event) => {
    this.props.onMakeOneTimeDonation();
  };

  onContinue = (event) => {
    this.props.onContinue();
  };

  render() {
    let secondDialogLabel;

    switch (this.props.selectedRecurringFrequency) {
      case 'WEEK':
        secondDialogLabel = 'dialogp2Week';
        break;
      case 'YEAR':
        secondDialogLabel = 'dialogp2Year';
        break;
      default:
        secondDialogLabel = 'dialogp2';
        break;
    }

    const dialogp2MsgFmt = new MessageFormat(this.context, {
      id: `common.recurring-checkbox.rp.${secondDialogLabel}`,
    });
    const dialogp2 = dialogp2MsgFmt.format({
      orgName: sanitizeOrgName(orgname),
    });

    return (
      <Sheet
        hideTitle
        center
        action={
          isInContext() ? <BackButton onClick={this.handleClose} /> : null
        }
        noCloseButton={isInContext()}
        isOpen={this.props.isOpen}
        onClose={this.handleClose}
        closeOnBackgroundClick={false}
        className="recurring-signup-overpanel"
        containerClassName={`${
          isInContext() ? 'incontext-guest-recurring' : ''
        }`}
      >
        <div className="modal-body">
          <div>
            <Row>
              <Col>
                <div className="icon-container">
                  <img
                    src={appendCdnHostName(
                      '/paypal-ui/pictograms/multicolored/svg/updating.svg'
                    )}
                    alt="icRecurring"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <HeadingText size="sm">
                  <Message id="common.recurring-checkbox.rp.signupHeader" />
                </HeadingText>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="content">
                  <div className="content-row">
                    <div className="icon-column">&#9679;</div>
                    <BodyText className="text-column">
                      <Message id="common.recurring-checkbox.rp.dialogp1passwordless" />
                    </BodyText>
                  </div>
                  <div className="content-row">
                    <div className="icon-column">&#9679;</div>
                    <BodyText className="text-column">{dialogp2}</BodyText>
                  </div>
                  <div className="content-row">
                    <div className="icon-column">&#9679;</div>
                    <BodyText className="text-column">
                      <Message id="common.recurring-checkbox.rp.dialogp3" />
                    </BodyText>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="actions">
              <Row className="mb-16">
                <Col>
                  <Button className="btn-wide" onClick={this.props.onContinue}>
                    <Message id="common.recurring-checkbox.rp.continueBtn" />
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    secondary
                    className="btn-wide"
                    id="noThanks"
                    onClick={this.noThanks_closeModal}
                  >
                    <Message id="common.recurring-checkbox.rp.noThanksBtn" />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Sheet>
    );
  }
}
RecurringOverlay.contextType = WorldReadyContext;
RecurringOverlay.propTypes = {
  closeRecurringOverlay: PropTypes.func,
  onMakeOneTimeDonation: PropTypes.func,
  onContinue: PropTypes.func,
  isOpen: PropTypes.bool,
  selectedRecurringFrequency: PropTypes.string,
};

export default RecurringOverlay;
