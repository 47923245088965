import React from 'react';
import PropTypes from 'prop-types';
import { AvatarStack } from '@paypalcorp/pp-react';
import { DonorAvatar } from '@paypalcorp/donate-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { formatNumber, getWorldReady } from '../utility/worldReadyUtils';
import _isEmpty from 'lodash/isEmpty';

const WhosDonatedBanner = ({
  numberOfContributors,
  contributionsList,
  isLoading,
}) => {
  let label;
  const worldReady = getWorldReady();

  if (numberOfContributors === 1) {
    const donorName = contributionsList?.[0]?.donor_name;
    const donatedAmount = contributionsList?.[0]?.amount;
    // checking for anonymous contributor
    label = _isEmpty(donorName)
      ? new MessageFormat(worldReady, {
          id: `landing.campaigns.oneAnonymousDonorMessage`,
        }).format()
      : new MessageFormat(worldReady, {
          id: `landing.campaigns.oneDonorMessage`,
        }).format({
          donor: donorName,
          amount: donatedAmount,
        });
  } else if (numberOfContributors === 2) {
    const donorOneName = contributionsList?.[0]?.donor_name;
    const donorTwoName = contributionsList?.[1]?.donor_name;
    // checking if any one of contributors is an anonymous contributor
    label =
      _isEmpty(donorOneName) || _isEmpty(donorTwoName)
        ? new MessageFormat(worldReady, {
            id: `landing.campaigns.donorMessage`,
          }).format({
            numberOfContributors: formatNumber(numberOfContributors),
          })
        : new MessageFormat(worldReady, {
            id: `landing.campaigns.twoDonorsMessage`,
          }).format({
            donorOneName: donorOneName,
            donorTwoName: donorTwoName,
          });
  } else {
    label = new MessageFormat(worldReady, {
      id: `landing.campaigns.donorMessage`,
    }).format({
      numberOfContributors: formatNumber(numberOfContributors),
    });
    contributionsList = contributionsList.slice(0, 3);
  }
  return (
    <>
      {!isLoading && contributionsList?.length > 0 && (
        <AvatarStack
          label={label}
          size="xs"
          data-testid="whos-donated-banner-container"
        >
          {contributionsList.map((data, index) => {
            const showAnonymousAvatar = !data.shareDonorInfo;
            return (
              <DonorAvatar
                photo={data?.photos?.[6]?.url}
                color={data.color}
                name={data?.donor_name}
                isAnonymousAvatar={showAnonymousAvatar}
                size="xs"
              />
            );
          })}
        </AvatarStack>
      )}
    </>
  );
};

WhosDonatedBanner.propTypes = {
  numberOfContributors: PropTypes.number,
  contributionsList: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default WhosDonatedBanner;
