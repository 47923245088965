import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu } from '@paypalcorp/pp-react-dropdown-menu';
import * as AppActions from '../../actions/AppActions';
import appStore from '../../stores/AppStore';
import { trackLinkClick } from '../../fpti/fpti';
import { FPTI_TAGS, LINK_NAME } from '../../fpti/fptiConstants';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

const Programs = ({ programNames, isDisabled }) => {
  const worldReady = useContext(WorldReadyContext);
  const [selectedProgram, handleSelect] = useState('');
  const programLabelMsgFmt = new MessageFormat(worldReady, {
    id: 'common.programs.programs.optional',
  });
  // programNames is coming as a string sometimes
  // Convert it to an array
  if (programNames && !Array.isArray(programNames)) {
    programNames = [programNames];
  }

  const getProgramOptions = () => {
    const options = [];
    programNames.forEach((programName) => {
      options.push({
        primaryText: programName,
        value: programName,
      });
    });

    return options;
  };

  const updateProgramId = (event) => {
    const {
      target: { value },
    } = event;
    handleSelect(value);
    AppActions.updateSelectedProgram(value);
    trackLinkClick(LINK_NAME.PROGRAM_SELECTED, {
      additionalData: {
        [FPTI_TAGS.CAMPAIGN]: value,
      },
    });
  };

  useEffect(() => {
    if (isDisabled) {
      handleSelect(programNames[0]);
    }
  }, [JSON.stringify(programNames)]);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    let programOption = getProgramOptions().findIndex(
      (program) => program.primaryText === appStore.getSelectedProgram()
    );
    programOption = programOption === -1 ? 0 : programOption;
    handleSelect(programNames[programOption]);
    AppActions.updateSelectedProgram(programNames[programOption]);
  }, []);

  return (
    <DropdownMenu
      id="programs"
      className="landing-programs"
      label={programLabelMsgFmt.format()}
      data-testid="programs-drowpdown"
      options={getProgramOptions()}
      value={selectedProgram}
      onChange={updateProgramId}
      disabled={isDisabled || false}
    />
  );
};

Programs.propTypes = {
  programNames: PropTypes.array,
  isDisabled: PropTypes.bool,
};

export default Programs;
