import { useEffect } from 'react';
import templateData from '../utility/getTemplateData';

let timerCount = 0;
export default function CancelAndReturnOrder() {
  const redirectToCancelUrl = () => {
    const { charityCancelUrl } = templateData;
    if (charityCancelUrl) {
      window.location.href = charityCancelUrl;
    }
  };

  useEffect(() => {
    // xprops takes a few miliseconds to be populated
    // because it has to set up the communication with host window
    // Wait a maximum of 2 seconds for the value
    waitForXProps(() => {
      // No xprops for 2 seconds
      // Maybe we are not inContext?
      // Redirect to cancel url
      redirectToCancelUrl();
    });
  }, []);

  function waitForXProps(callback) {
    timerCount++;
    if (timerCount > 10) {
      callback();
      return;
    }
    if (!window.xprops) {
      setTimeout(() => {
        waitForXProps(callback);
      }, 200);
    } else {
      window.xprops.close();
    }
  }

  return null;
}
