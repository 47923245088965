import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as AppActions from '../../actions/AppActions';
import { DonateCheckbox } from '@paypalcorp/donate-react';
import { addFeature } from '../../fpti/helper';
import { FEATURES } from '../../fpti/fptiConstants';
import { Message } from '@paypalcorp/worldready-react';

const CoverFeeCheckbox = ({
  handleCoverFeeChange,
  optInStatus,
  formatedFeeAmount,
  isDisabled,
}) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked,
      checkboxStatus = isChecked ? 'checked' : 'unchecked';

    AppActions.changeCoverFee({
      coverFee: checkboxStatus,
    });

    handleCoverFeeChange(isChecked);
  };

  useEffect(() => {
    addFeature(FEATURES.CTF);
  }, []);

  return (
    <DonateCheckbox
      className="cover-fee-checkbox checkbox-bottom-margin"
      id="cover-fee-checkbox"
      data-testid="cover-fee-checkbox"
      name="coverFee"
      checkbox_content={
        <Message
          id="common.coverFee.wr.labelA"
          amount={<strong id="fee-amount">{formatedFeeAmount}</strong>}
        />
      }
      value={optInStatus}
      onChange={handleChange}
      checked={optInStatus}
      disabled={isDisabled || false}
    />
  );
};

CoverFeeCheckbox.propTypes = {
  handleCoverFeeChange: PropTypes.func,
  optInStatus: PropTypes.bool,
  formatedFeeAmount: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default CoverFeeCheckbox;
