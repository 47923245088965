import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadBodyMovin } from '../../../utility/lazyLoader';
import { Checkbox, DropdownMenu } from '@paypalcorp/pp-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { getWorldReady } from '../../../utility/worldReadyUtils';
import RecurringInfo from '../RecurringInfo';
import { isLTR } from '../../../utility/helper';
import AppStore from '../../../stores/AppStore';

const RecurringCheckBox = ({ frequencies, handleFreqChange }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isChecked, setChecked] = useState(
    AppStore.getDonationRecurring() === 'checked'
  );
  const [selectedValue, setSelectedValue] = useState();
  const [hasOneFreq, setHasOneFreq] = useState();
  const worldReady = getWorldReady();
  const content = hasOneFreq
    ? new MessageFormat(worldReady, {
        id: 'common.recurring-checkbox.recurring_checkbox.one_frequency',
      }).format({
        frequency: frequencies[0].primaryText?.toLowerCase(),
      })
    : new MessageFormat(worldReady, {
        id: 'common.recurring-checkbox.recurring_checkbox.multiple_frequencies',
      }).format();

  useEffect(() => {
    const defaultFreq = frequencies[0]?.value;
    setSelectedValue(defaultFreq);
    setHasOneFreq(frequencies?.length === 1);
  }, [frequencies]);

  const loadAnimation = (display) => {
    if (!display) {
      return;
    }
    loadBodyMovin().then(() => {
      if (isAnimating) {
        return;
      }
      const animationData = window.heartsAnimationData;
      const bodymovinLibrary = window.bodymovin;
      if (!bodymovinLibrary || !animationData) {
        return;
      }
      setIsAnimating(true);
      bodymovinLibrary.loadAnimation({
        container: document.getElementById('animationContainer'),
        renderer: 'svg',
        loop: 1,
        autoplay: true,
        animationData,
      });

      // Destroying with timeout
      setTimeout(() => {
        bodymovinLibrary.destroy();
        setIsAnimating(false);
      }, 1000);
    });
  };

  const handleChange = (ev) => {
    let checkboxValue = ev.target.checked;
    let selectedFreq = !checkboxValue ? 'ONE_TIME' : selectedValue;
    setChecked(checkboxValue);
    handleFreqChange(ev, selectedFreq);

    loadAnimation(checkboxValue);
  };

  const handleDropdownSelection = (e) => {
    const freq = e.target.value;

    setSelectedValue(freq);

    handleFreqChange(e, freq);
  };

  return (
    <>
      <div
        className={`recurring-checkbox-wrapper ${
          isChecked
            ? 'recurring-checkbox-wrapper-checked'
            : 'recurring-checkbox-wrapper-unchecked'
        }`}
      >
        <div
          className={`animation-wrapper ${
            isAnimating ? 'block-wrapper' : 'none-wrapper'
          }`}
          id="animationWrapper"
        >
          <div
            className={`animation-container ${
              isLTR() ? 'ltr-container' : 'rtl-container'
            }`}
            id="animationContainer"
          />
        </div>
        <div className="recurring-checkbox">
          <Checkbox
            label={content}
            name="recurringCheckbox"
            id="recurringCheckbox"
            onChange={handleChange}
            checked={isChecked}
          />
        </div>
      </div>
      {isChecked && (
        <div className="recurring-checkbox-checked">
          {!hasOneFreq ? (
            <div className="recurring-dropdown">
              <DropdownMenu
                id="recurringDropdown"
                label={new MessageFormat(worldReady, {
                  id: 'common.recurring-checkbox.frequency',
                }).format()}
                options={frequencies}
                value={selectedValue}
                onChange={handleDropdownSelection}
              />
            </div>
          ) : (
            <RecurringInfo />
          )}
        </div>
      )}
    </>
  );
};

RecurringCheckBox.propTypes = {
  frequencies: PropTypes.array,
  handleFreqChange: PropTypes.func,
};

export default RecurringCheckBox;
