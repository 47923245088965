import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppStore from '../../stores/AppStore';
import _get from 'lodash/get';
import { Sheet } from '@paypalcorp/pp-react';

import { appendCdnHostName } from '../../utility/geoUtils';
import { trackErrorIM, trackThreeDSIframeIM } from '../../fpti/fpti';
import { FPTI_TAGS } from '../../fpti/fptiConstants';
import withTheme from '../../components/withTheme';
import { HeadingText } from '@paypalcorp/pp-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

class ThreeDSIframe extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  componentDidMount() {
    this.addEventListener();
    this.submitForm();

    trackThreeDSIframeIM({
      threeDSRespDuration: {
        [FPTI_TAGS.CHALLENGE_START_TIME]: new Date().getTime(),
      },
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessage);
  }

  addEventListener = () => {
    window.addEventListener('message', this.handleIframeMessage);
  };

  handleIframeMessage = (event) => {
    const source = _get(event, 'data.source');
    const confirmationStatus = _get(event, 'data.confirmationStatus');
    const error = _get(event, 'data.err');

    if (source !== 'paypal') {
      return;
    }

    if (error) {
      trackErrorIM({
        errorMessage: error,
        fieldName: '3DS Iframe',
        additionalData: {
          [FPTI_TAGS.CHALLENGE_STATUS]: confirmationStatus,
        },
      });
    }

    trackThreeDSIframeIM({
      threeDSRespDuration: {
        [FPTI_TAGS.CHALLENGE_END_TIME]: new Date().getTime(),
      },
      confirmationStatus,
    });

    this.props.onStepUpAuthComplete({
      confirmationStatus,
      error,
    });
    this.props.hideModal();
  };

  setFormRef = (element) => {
    this.formRef = element;
  };

  submitForm = () => {
    setTimeout(() => {
      if (this.formRef) {
        this.formRef.submit();
      }
    }, 100);
  };

  getModalBody = () => {
    const worldReady = this.context;
    const requestTextMsgFmt = new MessageFormat(worldReady, {
      id: 'common.threeDSecure.requestText',
    });
    const contingency = AppStore.getThreeDSContingency() || {};

    const { threeDS2Contingency } = contingency;

    const redirectLink = _get(threeDS2Contingency, '3d_secure_redirect_link');
    const jwt = _get(threeDS2Contingency, 'jwt');
    const fundingOptions = AppStore.getFundingOptions();
    const md = _get(fundingOptions, 'id', '');
    const redirectMethod = _get(
      threeDS2Contingency,
      'cardinal_redirect_method'
    );

    const requestText =
      requestTextMsgFmt.format() ||
      'Enter requested information to confirm your card';

    return (
      <div className="modal-body">
        <div className="text-center">
          <div className="container pp-logo-container">
            <img src={appendCdnHostName('/webstatic/icon/favicon.ico')} />
          </div>
          <HeadingText size="md" id="waitText" className="OTP-heading">
            {requestText}
          </HeadingText>
          <iframe
            title="ThreeDSIframe"
            name="stepUp"
            id="stepUp"
            frameBorder="0"
          >
            <form
              ref={this.setFormRef}
              name="stepUpForm"
              id="stepUpForm"
              target="stepUp"
              method={redirectMethod}
              action={redirectLink}
            >
              <input type="hidden" name="JWT" value={jwt} />
              <input type="hidden" name="MD" value={md} />
            </form>
          </iframe>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Sheet
        className="three-ds2-iframe-overpanel"
        title=""
        hideTitle={true}
        isOpen={this.props.isOpen}
        onClose={this.props.hideModal}
        hideLogo
        closeOnBackgroundClick={false}
      >
        {this.getModalBody()}
      </Sheet>
    );
  }
}

ThreeDSIframe.contextType = WorldReadyContext;

ThreeDSIframe.propTypes = {
  onStepUpAuthComplete: PropTypes.func,
  icon: PropTypes.string,
  hideModal: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
export default withTheme(ThreeDSIframe);
