import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CaptionText, SingleSelectionGroup } from '@paypalcorp/pp-react';
import AppStore from '../../../stores/AppStore';
import { RECURRING_EXPERIMENT } from '../../../../../lib/constants';
import { Message } from '@paypalcorp/worldready-react';

const RecurringSingleSelection = ({
  frequencies,
  handleFreqChange,
  showTitle,
}) => {
  const [selectedValue, setSelectedValue] = useState(frequencies[0]?.value);

  useEffect(() => {
    // let indx = 0;
    const selectedFrequency =
      AppStore.getSelectedRecurringFrequency() ?? frequencies[0]?.value;

    if (selectedFrequency === 'ONE_TIME') {
      setSelectedValue(selectedFrequency);
      return;
    }

    for (let i = 0; i < frequencies.length; i++) {
      if (
        RECURRING_EXPERIMENT.VALUE[frequencies[i].value] === selectedFrequency
      ) {
        setSelectedValue(frequencies[i].value);
        break;
      }
    }
  }, [frequencies]);

  const handleSelection = (e) => {
    const value = e.target.value;
    setSelectedValue(value);

    handleFreqChange(e, value);
  };

  return frequencies?.length > 0 ? (
    <div className="frequency-options">
      {showTitle && (
        <CaptionText>
          <Message id={`common.recurring-checkbox.frequency`} />
        </CaptionText>
      )}
      <SingleSelectionGroup
        name="RecurringSingleSelection"
        value={selectedValue}
        align="justify"
        options={frequencies.map((option) => ({
          label: option.primaryText,
          value: option.value,
          id: `${option.primaryText}-id`,
        }))}
        onChange={handleSelection}
      />
    </div>
  ) : null;
};

RecurringSingleSelection.propTypes = {
  frequencies: PropTypes.array,
  handleFreqChange: PropTypes.func,
  showTitle: PropTypes.bool,
};

export default RecurringSingleSelection;
