export const PRODUCT = {
  BRANDED_CAMPAIGN: 'BRANDED_CAMPAIGN',
  GENEROSITY_NETWORK: 'GENEROSITY_NETWORK',
  GENEROSITY_NETWORK_CHARITIES: 'GENEROSITY_NETWORK_CHARITIES',
  PP_FUNDRAISING: 'PP_FUNDRAISING',
  DONATE_BUTTON_CAMPAIGN: 'DONATE_BUTTON_CAMPAIGN',

  // externalIntegeration
  PAYPAL_FUNDRAISER_LISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_LISTED_CAMPAIGN',
  PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN',
};

export const INTEGRATION_IDENTIFIER = {
  DIRECT: 'DIRECT',
  PPGF: 'PPGF', // PayPal Giving Fund
};

export const P2P_INTEGRATION_IDENTIFIERS = {
  GENEROSITY_NETWORK: '8BALL_CROWDFUNDING_MONEY_POOLS',
  PAYPAL_FUNDRAISER_LISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_LISTED_CAMPAIGN',
  PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN',
};

export const PRODUCT_TYPE_IDENTIFIER = {
  DONATE_BUTTON: 'DB',
  DONATE_BUTTON_CAMPAIGN: 'DC',
};

// listing all possible products - may not be used directly in code, but good to have it here
export const PAYMENT_PRODUCT = {
  PUBLIC_MONEY_POOL: 'PUBLIC_MONEY_POOL',
  DONATE_BUTTON: 'DONATE_BUTTON',
  DONATE_BUTTON_CAMPAIGN: 'DONATE_BUTTON_CAMPAIGN',
  CROWDFUNDING_CAMPAIGNS: 'CROWDFUNDING_CAMPAIGNS',
};

// Post payment product type identifiers
export const PRODUCT_TYPE = {
  DBUTTON: 'DBUTTON',
  DBUTTONC: 'DBUTTONC',
  DONATE_BUTTON: 'DONATE_BUTTON',
  RECURRING_DONATION: 'SEND_MONEY_RECURRING_DONATION',
};

export const AU_CRS = {
  STATUS: {
    ABORTED: 'ABORTED',
    FAILURE: 'FAILURE',
    SUCCESS: 'SUCCESS',
  },
  ABORTED_STATUS_CODE: ['DOC_UPLOAD_PENDING', 'INFO_NOT_COLLECTED'],
  FAILURE_STATUS_CODE: [
    'INVALID_REQUEST',
    'NOT_APPLICABLE',
    'SERVICE_UNAVAILABLE',
    'THIRD_PARTY_VERIFICATION_FAIL',
    'UNAUTHORIZED',
  ],
  SUCCESS_STATUS_CODE: ['COMPLETED', 'VERIFIED'],
};

export const RECURRING_EXPERIMENT = {
  TREATENTS: {
    Ctrl_Giving_donate_recurring_checkbox: 'CONTROL',
    Trmt_1_Giving_donate_segmented_controller: 'TRMT_1_SEGMENTED_CONTROLLER',
    Trmt_2_Giving_donate_selection_group_header_above:
      'TRMT_2_SELECTION_GROUP_ABOVE',
    Trmt_3_Giving_donate_selection_group_header_bellow:
      'TRMT_3_SELECTION_GROUP_BELOW',
  },
  CONTENT_KEYS: {
    ONE_TIME: 'oneTime',
    WEEK: 'weekly',
    MONTH: 'monthly',
    YEAR: 'yearly',
  },
  VALUE: {
    ONE_TIME: null,
    WEEK: 'Week',
    MONTH: 'Month',
    YEAR: 'Year',
  },
  DISCLAIMER_CONTENT: 'helpText1',
};

export const defaultloggingBlocklist = [
  'password',
  'pr_password',
  'email',
  'cardNumber',
  'expiry_value',
  'cvv',
  'firstName',
  'lastName',
  'fullName',
  'billingLine1',
  'billingLine2',
  'telephone',
  'shippingFirstName',
  'shippingLastName',
  'shippingLine1',
  'shippingLine2',
  'giftAidAddress',
  'bankAccountNumber',
];

export const PII = '**PII**';
