import React from 'react';
import { withRouter } from 'react-router-dom';
import withTheme from '../../components/withTheme';
import templateData from '../../utility/getTemplateData';
import { hideSpinnerOverlay } from '../../utility/helper';
import { Button } from '@paypalcorp/pp-react';
import Illustration from '../../components/illustrations/Illustration';
import ConfirmationTitle from '../../confirmation/ConfirmationTitle';
import ConfirmationBody from '../../confirmation/ConfirmationBody';
import { isMobileOrInContext } from '../../utility/inContextDonation';
import { isExternalFlow } from '../../utility/productIntegrationUtils';
import CloseButton from '../../components/CloseButton';
import { Row, Col } from '@paypalcorp/pp-react';
import { Message } from '@paypalcorp/worldready-react';
import withWorldReady from '../../components/withWorldReady';
class ThreeDSError extends React.Component {
  componentDidMount = () => {
    hideSpinnerOverlay();
  };

  handleTryAgain = () => {
    this.props.history.push(`/donate?token=${templateData.token}`);
  };

  render() {
    return (
      <div>
        <div
          className={`donate_wrapper confirmation ${
            isMobileOrInContext() ? 'mobile_view' : ''
          }`}
        >
          {isExternalFlow() && <CloseButton />}
          <Row>
            <Col align="center">
              <Illustration type="warning" />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <ConfirmationTitle
                message={<Message id="errors.threeDSError.errorMessage" />}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <ConfirmationBody
                message={<Message id="errors.threeDSError.tryAgain" />}
              />
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <Button
                className="btn-wide"
                id="tryAgain"
                onClick={this.handleTryAgain}
              >
                <Message id="errors.threeDSError.tryCard" />
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withWorldReady(withRouter(withTheme(ThreeDSError)));
