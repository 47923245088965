import React from 'react';
import AppStore from '../../stores/AppStore';
import { withRouter } from 'react-router-dom';
import withTheme from '../../components/withTheme';
import templateData from '../../utility/getTemplateData';
import { hideSpinnerOverlay } from '../../utility/helper';
import { trackErrorPageIM } from '../../fpti/fpti';
import { PAGE_SPEC } from '../../fpti/fptiConstants';
import { addClass, removeClass } from '../../utility/domUtils';
import FullPageError from './FullPageError';
import MessageWithHTML from '../../components/MessageWithHtml';
import ReceiverEligibilityErrorTile from '../error/receiverEligibilityError';
import { isMobileOrInContext } from '../../utility/inContextDonation';
import {
  Context as WorldReadyContext,
  Message,
} from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';

class PaymentError extends React.Component {
  static contextType = WorldReadyContext;

  constructor() {
    super();
    this.state = {};
  }

  getErrorMessage = (id, params) => {
    return new MessageFormat(this.context, {
      id: `errors.paymentError.error.${id}`,
    }).format(params);
  };

  componentDidMount = () => {
    addClass('#mainWrapper', ['full-page-error']);
    hideSpinnerOverlay();
    const ERROR_CODE = AppStore.getPaymentError() || '';
    trackErrorPageIM({
      pageSpec: PAGE_SPEC.GUEST_FAILURE_PAGE,
      errorCode: ERROR_CODE,
    });
  };

  componentWillUnmount() {
    removeClass('#mainWrapper', ['full-page-error']);
  }

  handleTryAgain = () => {
    this.props.history.push(`/donate?token=${templateData.token}`);
  };

  render() {
    const ERROR_CODE = AppStore.getPaymentError() || '';

    let WENT_WRONG = '',
      TEMP_GLITCH = '',
      tryagain = '';

    const hideButton = ERROR_CODE === 'WSM_PAYER_LIMIT_EXCEEDED';

    switch (ERROR_CODE) {
      case 'WSM_PAYMENT_DENIED':
        WENT_WRONG = <Message id="errors.paymentError.error.DID_NOT_WORK" />;
        TEMP_GLITCH = (
          <Message id="errors.paymentError.error.DIFF_CREDIT_DEBIT" />
        );
        tryagain = <Message id="errors.paymentError.label.trydiffcard" />;
        break;
      case 'WSM_CANNOT_PAY_SELF':
        WENT_WRONG = (
          <Message id="errors.paymentError.error.WSM_CANNOT_PAY_SELF_H" />
        );
        TEMP_GLITCH = this.getErrorMessage('WSM_CANNOT_PAY_SELF', {
          ['data.htmlTagsProps.name']: templateData.donationName,
        }).replace('{data.htmlTagsProps.name}', templateData.donationName);
        break;
      case 'WSM_PAYER_LIMIT_EXCEEDED':
        WENT_WRONG = (
          <Message id="errors.paymentError.error.AmountLimitBreachError" />
        );
        TEMP_GLITCH = (
          <MessageWithHTML
            id="errors.paymentError.error.CustomerCareMessage"
            customerServiceLink={'/selfhelp/home'}
          />
        );
        break;
      default:
        break;
    }

    if (ERROR_CODE === 'WSM_PAYEE_TRANSACTION_LIMIT_EXCEEDED') {
      return (
        <div
          className={`receiver-eligibility-error-container fullPageError ${
            isMobileOrInContext() ? 'mobile_view' : ''
          }`}
        >
          <ReceiverEligibilityErrorTile
            organizationName={templateData.donationName}
            returnUrl={templateData.charityReturnUrl}
          />
        </div>
      );
    }
    return (
      <div>
        <FullPageError
          title={
            WENT_WRONG ||
            'We’re sorry that we are experiencing temporary difficulties.'
          }
          body={
            TEMP_GLITCH ||
            'Please try again later. We apologize for the inconvenience.'
          }
          buttonLabel={hideButton ? null : tryagain || 'Return to PayPal'}
          handleButtonClick={this.handleTryAgain}
          showCloseButton={true}
        />
      </div>
    );
  }
}

export default withRouter(withTheme(PaymentError));
