import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Checkbox, CaptionText, Button } from '@paypalcorp/pp-react';
import DonateGiftAidHowItWorksOverlay from './DonateGiftAidHowItWorksOverlay';
import { appendCdnHostName } from '../utility/geoUtils';
import { BodyText } from '@paypalcorp/pp-react-text';
import { Message } from '@paypalcorp/worldready-react';
import { normalizeNumber, formatAmount } from '../utility/formator';

const DonateGiftAid = ({
  isAlreadyEnrolled,
  isChecked,
  handleGiftAidOption,
  addressData,
  showAddress,
  giftaidInfo,
}) => {
  const [showHelp, setShowHelp] = useState(isChecked);
  const [checked, setChecked] = useState(isChecked);
  const [isHowItWorksOverlayOpen, setIsHowItWorksOverlayOpen] = useState(false);
  const { donateGiftPercentage, donationCode, amount, orgName } = giftaidInfo;

  useEffect(() => {
    if (isAlreadyEnrolled) {
      setShowHelp(false);
    }
  }, [isAlreadyEnrolled]);

  const handleCheckbox = (env) => {
    const checkedValue = env.target.checked;
    setChecked(checkedValue);
    setShowHelp(checkedValue);
    handleGiftAidOption(checkedValue);
  };

  const openHowItWorksOverlay = () => {
    setIsHowItWorksOverlayOpen(true);
  };

  const closeHowItWorksOverlay = () => {
    setIsHowItWorksOverlayOpen(false);
  };

  const getGiftAidExtraAmount = () => {
    const giftaidAmount = donateGiftPercentage * normalizeNumber(amount);
    return formatAmount({ value: giftaidAmount, currency: donationCode });
  };

  const getPaymentAmount = () =>
    formatAmount({ value: normalizeNumber(amount), currency: donationCode });

  return (
    <div className="donate-giftaid">
      <div className="giftaid">
        <img
          className="image-wrapper"
          src={appendCdnHostName('/webstatic/ppgf/giftaid/GiftAidWPS.png')}
          alt="giftaidit"
        />
        <BodyText className="giftaid-note">
          <Message
            id="common.giftAidIt.wr.giftAidIt.extraCost"
            currency={''}
            amount={<strong>{getPaymentAmount()}</strong>}
            extraAmount={<strong>{getGiftAidExtraAmount()}</strong>}
          />
          <Button
            type="button"
            onClick={openHowItWorksOverlay}
            className="learn-more-button"
            tertiary
          >
            <Message id="common.giftAidIt.giftAidIt.learnMore" />
          </Button>
        </BodyText>

        {isAlreadyEnrolled && (
          <div className="already-enabled">
            <img
              className="success-icon"
              src={appendCdnHostName(
                '/paypal-ui/illustrations/focused/svg/empty-success.svg'
              )}
            />
            <BodyText>
              <Message id="common.giftAidIt.giftAidIt.alreadyDone" />
            </BodyText>
          </div>
        )}
        {!isAlreadyEnrolled && (
          <Checkbox
            label={<Message id="common.giftAidIt.giftAidIt.optGiftaid" />}
            name="giftaidCheckbox"
            id="giftaidCheckbox"
            className="inline-block"
            onChange={handleCheckbox}
            checked={checked}
          />
        )}

        {showHelp && (
          <div>
            <div className="show-help">
              <CaptionText className="help-message">
                <Message
                  id="common.giftAidIt.giftAidIt.applyGiftAid"
                  orgName={orgName}
                />
              </CaptionText>
            </div>
            {showAddress && (
              <div className="show-adress">
                <CaptionText strong>
                  <Message id="common.giftAidIt.giftAidIt.taxpayer" />
                </CaptionText>
                <ul className="list-unstyle">
                  <li>{addressData.fullName}</li>
                  <li>{addressData.address1}</li>
                  <li>{addressData.address2}</li>
                  <li>{addressData.city}</li>
                  <li>
                    {addressData.state}, {addressData.zip}
                  </li>
                </ul>
              </div>
            )}
            <div className="show-help">
              <CaptionText>
                <Message id="common.giftAidIt.giftAidIt.confirmNote" />
              </CaptionText>
            </div>
          </div>
        )}

        <DonateGiftAidHowItWorksOverlay
          isOpen={isHowItWorksOverlayOpen}
          openOverlay={openHowItWorksOverlay}
          closeOverlay={closeHowItWorksOverlay}
          orgName={orgName}
        />
      </div>
    </div>
  );
};

DonateGiftAid.propTypes = {
  isAlreadyEnrolled: PropTypes.bool,
  showAddress: PropTypes.bool,
  addressData: PropTypes.any,
  isChecked: PropTypes.bool,
  handleGiftAidOption: PropTypes.func,
  giftaidInfo: PropTypes.object,
  isMember: PropTypes.bool,
};

export default DonateGiftAid;
