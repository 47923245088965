import React from 'react';
import AppStore from '../../stores/AppStore';
import * as AppActions from '../../actions/AppActions';
import TemplateData from '../../utility/getTemplateData';
import PropTypes from 'prop-types';
import { showSpinnerOverlay, hideSpinnerOverlay } from '../../utility/helper';
import CancelAndReturn from '../../components/cancelAndReturn';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import DonateGiftAid from '../../guest-payment-components/DonateGiftAid';
import { formatCurrencyAsString } from '../../utility/formator';
import { withRouter } from 'react-router-dom';
import { observeUntilEventObserved } from '../../utility/helper';
import { fetcher } from '../../utility/domUtils';
import CVVComponent from '../../containers/cvv-component';
import withDonate from '../../components/guest/WithDonate';
import withTheme from '../../components/withTheme';
import ThreeDSContainer from '../../components/threeDS/ThreeDSContainer';
import {
  Alert,
  Button,
  BodyText,
  CaptionText,
  Row,
  Col,
} from '@paypalcorp/pp-react';
import CloseButton from '../../components/CloseButton';
import BackButton from '../../components/BackButton';
import DonateHeader from '../../components/common/DonateHeader';
import PaymentInformation from './guestReviewComponents/PaymentInformation';
import CrossBorder from './guestReviewComponents/CrossBorder';
import FeePricing from './guestReviewComponents/FeePricing';
import {
  isExternalFlow,
  isPPUnlistedCampaign,
} from '../../utility/productIntegrationUtils';
import { isInContext } from '../../utility/inContextDonation';
import {
  track3DSProceedToPayment,
  trackErrorIM,
  trackGuestReviewPageDonateClick,
  trackLinkClick,
} from '../../fpti/fpti';
import { LINK_NAME, PAGE_SPEC } from '../../fpti/fptiConstants';
import withWorldReady from '../../components/withWorldReady';
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';
import MessageWithHTML from '../../components/MessageWithHtml';

const donateGiftPercentage = AppStore.getGiftAidItExtraAmount();

export const CURRENCY_CONVERSION_OPTIONS = {
  PAYPAL: 'PAYPAL',
  ISSUER: 'ISSUER',
};

class GuestReview extends React.Component {
  constructor(props) {
    super(props);

    const isRecurring = AppStore.getDonationRecurring() === 'checked';
    const stateConversionWithPayPal = this.getStateForConversionWithPayPal();

    this.state = {
      subHeaderLabel: TemplateData.donationName,
      donationId: AppStore.getSelectedProgram() || TemplateData.donationId,
      orgName: TemplateData.charityName,
      amountDecimalPoint: TemplateData.donationDecimalPoint || '.',
      decimalPoint: TemplateData.donationDecimalPoint || '.',
      isRecurring,
      ...stateConversionWithPayPal,
      showCVVField: false,
      securityCode: '',
      isCurrencyConversionModalOpen: false,
      selectedRecurringFrequency: AppStore.getSelectedRecurringFrequency(),
    };
    this.getFundingOptions = this.getFundingOptions.bind(this);
  }

  endCustomCPLTracking = () => {
    const spinnerOverlay = document.getElementById('spinnerOverlay');

    if (spinnerOverlay.style.display === 'none') {
      // From tesla flow we don't have the spinner
      window.PAYPAL.analytics.endCPLTracking({
        page: 'main:donate:wps:guest:crossBorder:web::',
        action: 'crossborder_page_loaded',
        uicomp: 'crossBorder',
        uitype: 'page',
      });
    } else {
      // From guest form we need to wait for the spinner to disappear
      observeUntilEventObserved(
        spinnerOverlay,
        { attributes: true },
        (observer) => {
          if (spinnerOverlay.style.display === 'none') {
            window.PAYPAL.analytics.endCPLTracking({
              page: 'main:donate:wps:guest:crossBorder:web::',
              action: 'crossborder_page_loaded',
              uicomp: 'crossBorder',
              uitype: 'page',
            });

            observer.disconnect();
            return;
          }
        }
      );
    }
  };

  componentDidMount() {
    this.endCustomCPLTracking();
  }

  getStateForConversionWithPayPal() {
    const fundingOptions = AppStore.getFundingOptions();
    const myData = AppStore.getAllData() || {};
    const cardType = _get(myData, 'cardSpec.cardSpec.type', '')
      .replace(/[.,_]|\s\(.*\)/g, '')
      .replace(/[\s_]/g, '-')
      .toLowerCase();
    const [fundingSources] = fundingOptions ? fundingOptions.sources : [];
    let paymentNetwork = fundingSources.payment_card.network.toLowerCase();
    paymentNetwork =
      paymentNetwork.charAt(0).toUpperCase() + paymentNetwork.slice(1);
    const paymentDigits = fundingSources.payment_card.last_4;
    let paymentType = (fundingSources?.payment_card?.type ?? '').toLowerCase();
    paymentType =
      paymentType && paymentType.charAt(0).toUpperCase() + paymentType.slice(1);
    const issuer = fundingSources.payment_card.issuer.name;
    const exchangeRate = parseFloat(
      fundingOptions.currency_conversion?.exchange_rate || '0.00'
    ).toString();
    const fundsIn = fundingOptions.currency_conversion?.funds_in || '0.00';
    const fundsOut = fundingOptions.currency_conversion?.funds_out || '0.00';
    const feeAmounts = fundingOptions.fee_amount || null;
    const amount = this.getLocalizedCurrency(
      fundingSources.payment_card.amount.value,
      fundingSources.payment_card.amount.currency
    );

    return {
      exchangeRate,
      paymentCard: `${paymentNetwork} x-${paymentDigits}`,
      paymentDigits,
      paymentType,
      source: fundingSources,
      issuerName: issuer,
      inAmount: fundsIn.value,
      inCurrency: fundsIn.currency,
      inSymbol: fundsIn.symbol,
      outAmount: fundsOut.value,
      outCurrency: fundsOut.currency,
      outSymbol: fundsOut.symbol,
      currencyConversionOption: CURRENCY_CONVERSION_OPTIONS.PAYPAL,
      cardType,
      feeAmounts,
      currencyConversion: fundingOptions.currency_conversion,
      fundingCode: !_isEmpty(fundingOptions.currency_conversion)
        ? 'CROSS_BORDER'
        : 'PAYER_FEE',
      currencyCode: fundingOptions.currencyCode,
      amount,
    };
  }

  getStateForConversionWithIssuer() {
    const fundingOptions = AppStore.getFundingOptions();
    const [fundingSources] = fundingOptions ? fundingOptions.sources : [];
    const amount = this.getLocalizedCurrency(
      fundingSources.payment_card.amount.value,
      fundingSources.payment_card.amount.currency
    );
    const symbol = AppStore.getDonationSymbol();
    const currency = AppStore.getDonationCode();
    const feeAmounts = fundingOptions.fee_amount || null;

    // No currency_conversion. fundsIn and fundsOut will not be available.
    return {
      amount,
      symbol,
      currency,
      currencyConversionOption: CURRENCY_CONVERSION_OPTIONS.ISSUER,
      currencyConversion: fundingOptions.currency_conversion,
      feeAmounts,
    };
  }

  updateFundingOptionsState() {
    const fundingOptions = AppStore.getFundingOptions();
    if (!fundingOptions.currency_conversion) {
      this.setState(this.getStateForConversionWithIssuer());
    } else {
      this.setState(this.getStateForConversionWithPayPal());
    }
  }

  getLocalizedCurrency = (amount, currency, totalDecimals = 2) => {
    const formatedAmount = {
      value: parseFloat(amount).toFixed(totalDecimals),
      currency: currency,
      maximumFractionDigits: totalDecimals,
    };
    const newAmount = formatCurrencyAsString(formatedAmount);
    return newAmount;
  };

  handleCurrencyConversionClick = () => {
    trackLinkClick(LINK_NAME.CURRENCY_CONVERSION, {
      pageSpec: PAGE_SPEC.CROSS_BORDER_PAGE,
    });
    this.showCurrencyConversionModal();
  };

  showCurrencyConversionModal = () => {
    this.setState({
      isCurrencyConversionModalOpen: true,
    });
  };

  hideCurrencyConversionModal = () => {
    this.setState({
      isCurrencyConversionModalOpen: false,
    });
  };

  handleDonateClick = () => {
    if (this.state.showCVVField) {
      const cvv = document.getElementById('securityCode').value;
      this.childCVVInfo.handleFieldBlur(
        cvv,
        'errorMessageCSC',
        'REQUIRED_SECURITY_CODE',
        'INVALID_SECURITY_CODE'
      );

      const hasError =
        document.getElementsByClassName('vx_has-error-with-message').length > 0;
      if (!cvv || hasError) {
        return;
      }
    }
    const fundingOptions = AppStore.getFundingOptions();
    const contingencies = _get(fundingOptions, 'contingencies') || [];
    const threeDSContingency =
      _find(contingencies, { action: '3D_SECURE_DATA_COLLECTION_REQUIRED' }) ||
      {};

    if (!_isEmpty(threeDSContingency)) {
      this.threeDSContainer.initializeThreeDS();
      return;
    }

    // All contingencies resolved
    this.processDonation();
  };

  processDonation = () => {
    showSpinnerOverlay();

    trackGuestReviewPageDonateClick({
      isCvvSend: this.state.securityCode?.length > 0,
    });

    return this.props.doFulfilment({
      securityCode: this.state.securityCode,
    });
  };

  handleGiftAidOption = (giftAidState) => {
    AppActions.updateGiftaidItFlag({ giftAidItFlag: giftAidState });
  };

  getFundingOptions(currencyConversionType) {
    let fundingOptions = AppStore.getFundingOptions();
    const fundingSources = fundingOptions.sources || [];
    const lastFundingSource = fundingSources[fundingSources.length - 1];
    const paymentCardId = _get(lastFundingSource, 'payment_card.id');

    return fetcher('/donate/guest/fundingOptions', {
      method: 'POST',
      body: JSON.stringify({
        amount: AppStore.getDonationAmount(),
        code: AppStore.getDonationCode(),
        fundingOptionId: AppStore.getFundingOptions().id,
        preferences: {
          currency_conversion: [
            {
              payment_card_id: paymentCardId,
              type: currencyConversionType,
              validity: 'TRANSACTION',
            },
          ],
        },
        consentShareAddress: AppStore.getShareAddress(),
        token: TemplateData.token,
        onboardingEnabled: TemplateData.onboardingEnabled,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // The funding options data format returned by the server is
        // different for ISSUER and PAYPAL. Handle it appropriately.

        // For conversion with 'ISSUER'
        if (currencyConversionType === CURRENCY_CONVERSION_OPTIONS.ISSUER) {
          fundingOptions = _get(result, 'data.fundingOpt[0]');
        } else {
          // For conversion with 'PAYPAL'
          fundingOptions = _get(result, 'fundingOpts[0]');
        }

        // Handle error case where fundingOptions call succeeds
        // but no data is returned
        if (_isEmpty(fundingOptions)) {
          trackErrorIM({
            errorCode: currencyConversionType,
            errorMessage: 'FUNDING_OPTIONS_EMPTY_CROSSBORDER',
          });
          this.props.history.push(
            `/donate/error/payment?token=${TemplateData.token}`
          );
          return;
        }

        // If CVV contingencies are present and has not been resolved,
        // then display cvv component
        const contingencies = _get(fundingOptions, 'contingencies');
        const hasCvvContingency = _find(contingencies, {
          action: 'CVV_REQUIRED',
        });
        const isCvvContingencyResolved = _get(
          result,
          'data.contingencyResolutionStatus.cvvContingencyResolved',
          false
        );

        this.setState({
          showCVVField: hasCvvContingency && !isCvvContingencyResolved,
        });

        // Updating store with funding options
        AppActions.updateFundingOptions({ fundingOptions });

        this.updateFundingOptionsState();
      })
      .catch(() => {
        this.props.history.push(
          `/donate/error/payment?token=${TemplateData.token}`
        );
      })
      .finally(() => {
        hideSpinnerOverlay();
      });
  }

  handleSecurityCodeChange = (securityCode) => {
    this.setState({
      securityCode: securityCode,
    });
  };

  handleThreeDSStepUpAuthSuccess = () => {
    this.props.toggleSpinner(true);

    track3DSProceedToPayment({
      isCvvSend: this.state.securityCode?.length > 0,
    });

    this.props.doFulfilment({
      securityCode: this.state.securityCode,
    });
  };

  handleThreeDSStepUpAuthFailure = () => {
    // Unrecoverable error !!
    this.props.history.push('/donate/error/payment');
  };

  handleThreeDSFailure = () => {
    // Unrecoverable error !!
    this.props.history.push('/donate/error/threeDS');
  };

  render() {
    const worldReady = this.context;
    const inAmount = this.getLocalizedCurrency(
      this.state.inAmount,
      this.state.inCurrency
    );
    const outAmount = this.getLocalizedCurrency(
      this.state.outAmount,
      this.state.outCurrency
    );

    const giftaidFlag = AppStore.getGiftaidItFlagStatus();

    const getRecurringLabel = () => {
      switch (this.state.selectedRecurringFrequency) {
        case 'Week':
          return 'donateNowRec.weekly';
        case 'Month':
          return 'donateNowRec.monthly';
        case 'Year':
          return 'donateNowRec.yearly';
        default:
          return 'donateNowRec.monthly';
      }
    };

    const { showCVVField } = this.state;

    return (
      <div
        className={`${
          TemplateData.bannerUrl ? 'gn_donate_wrapper' : 'donate_wrapper'
        } ${isInContext() ? 'context_gn_wrapper' : ''}`}
      >
        <DonateHeader />
        {showCVVField && (
          <Row className="alert-container mb-8">
            <Alert type="warning">
              <Message
                id="guest.guestForm.label.cscwarning"
                csc={new MessageFormat(worldReady, {
                  id: 'guest.guestForm.label.csc',
                })
                  .format()
                  .toLowerCase()}
              />
            </Alert>
          </Row>
        )}
        {isExternalFlow() && (
          <>
            <BackButton inverse />
            <CloseButton inverse />
          </>
        )}

        <div id="crossBorderContainer" className="cross-border">
          {TemplateData.isGiftaiditEnabled && (
            <DonateGiftAid
              isAlreadyEnrolled={giftaidFlag}
              showAddress={false}
              addressData=""
              isChecked={giftaidFlag}
              handleGiftAidOption={this.handleGiftAidOption}
              giftaidInfo={{
                currency: this.state.outSymbol,
                amount: this.state.outAmount,
                orgName: this.state.subHeaderLabel,
                donateGiftPercentage: donateGiftPercentage,
                donationCode: AppStore.getDonationCode(),
              }}
            />
          )}
          <Row>
            <Col form="full">
              <BodyText style={{ margin: '28px 0' }}>
                <Message id="guest.crossBorder.label.donatingFrom" />
              </BodyText>
            </Col>
          </Row>
          <PaymentInformation
            fundingOptions={AppStore.getFundingOptions()}
            paymentDigits={this.state.paymentDigits}
            paymentType={this.state.paymentType}
            source={this.state.source}
            issuer={this.state.issuerName}
            isPaypalCurrencyConversion={
              this.state.currencyConversionOption ===
              CURRENCY_CONVERSION_OPTIONS.PAYPAL
            }
            funds={this.state.amount}
            cardType={this.state.cardType}
          />

          {showCVVField && (
            <CVVComponent
              onRef={(ref) => (this.childCVVInfo = ref)}
              handleSecurityCodeChange={this.handleSecurityCodeChange}
            />
          )}

          {isPPUnlistedCampaign() && !_isEmpty(this.state.feeAmounts) && (
            <FeePricing
              feeAmounts={this.state.feeAmounts}
              getLocalizedCurrency={this.getLocalizedCurrency}
            />
          )}

          {this.state.fundingCode === 'CROSS_BORDER' && (
            <CrossBorder
              hasFees={!_isEmpty(this.state.feeAmounts)}
              isPaypalCurrencyConversion={
                this.state.currencyConversionOption ===
                CURRENCY_CONVERSION_OPTIONS.PAYPAL
              }
              funds={
                this.state.currencyConversionOption ===
                CURRENCY_CONVERSION_OPTIONS.PAYPAL
                  ? inAmount
                  : this.state.amount
              }
              getFundingOptions={this.getFundingOptions}
              currencyConversionOption={this.state.currencyConversionOption}
              getLocalizedCurrency={this.getLocalizedCurrency}
              setSelectedOption={(currencyConversionType) =>
                this.setState({
                  currencyConversionOption: currencyConversionType,
                })
              }
              stateCurrency={{
                inCurrency: this.state.inCurrency,
                exchangeRate: this.state.exchangeRate,
                amountDecimalPoint: this.state.amountDecimalPoint,
                inSymbol: this.state.inSymbol,
                outCurrency: this.state.outCurrency,
              }}
            />
          )}

          <div className="legal-text text-center">
            <CaptionText>
              <MessageWithHTML
                id="guest.crossBorder.label.policies"
                country={TemplateData.country}
              />
            </CaptionText>
          </div>

          <div className="text-center">
            <Button
              className="btn-wide"
              id="donate-button"
              disabled={this.props.disabled}
              onClick={this.handleDonateClick}
            >
              {this.state.isRecurring ? (
                <Message
                  id={`guest.guestForm.label.${getRecurringLabel()}`}
                  amount={outAmount}
                />
              ) : (
                <Message id="guest.guestForm.label.donatenow" />
              )}
            </Button>
          </div>
          <div className="cross-border-cancel-return">
            <CancelAndReturn />
          </div>

          <ThreeDSContainer
            onThreeDSStepUpAuthNotRequired={this.handleThreeDSStepUpAuthSuccess}
            onThreeDSStepUpAuthSuccess={this.handleThreeDSStepUpAuthSuccess}
            onThreeDSStepUpAuthFailure={this.handleThreeDSFailure}
            onThreeDSFailure={this.handleThreeDSFailure}
            onRef={(ref) => (this.threeDSContainer = ref)}
          />
        </div>
      </div>
    );
  }
}

GuestReview.propTypes = {
  doFulfilment: PropTypes.func,
  history: PropTypes.object,
  toggleSpinner: PropTypes.func,
  disabled: PropTypes.bool,
};

GuestReview.contextType = WorldReadyContext;

export default withRouter(withWorldReady(withDonate(withTheme(GuestReview))));
